._2BW_Hz {
  padding: 30px 0;
}
._1_i8Ol {
  color: #FFFFFF;
  padding: 0 10px;
}
.KzQbk2 {
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  background-color: transparent;
  width: 40px;
  height: 40px;
  color: #FFFFFF;
  font-size: 24px;
}
._1ubLRr {
  background-color: #FFFFFF;
  color: #007a9f;
}
._3xxGL6 {
  border-color: transparent;
  background-color: #007a9f;
}
._3Am6-U {
  fill: #FFFFFF;
  color: #FFFFFF;
}
._5x9gBP {
  width: 50px;
  height: 1px;
  background-color: #FFFFFF;
}
._1nTEC1 {
  background-color: #007a9f;
}
