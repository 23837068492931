._3R0ihN {
  margin-top: 20px;
  margin-bottom: 10px;
}
._57e7At {
  font-size: 16px;
}
._3W8yvK {
  color: #727272;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.75
}
._3W8yvK:after {
    content: ':';
  }
._2DJurv {
  font-size: 32px;
  line-height: 1.16;
  text-align: left;
}
._3DYcB1 {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  padding: 10px 20px;
  font-size: 24px;
  margin-bottom: 10px;
}
.IeLT9a {
}
