.uwyZ-M {
}
._1w1dyh ._2ufKIi {
    color: #009245;
  }
.I2Ux5x ._2ufKIi {
    color: #FFA000;
  }
._2C3qzg {
  margin-top: 3px;
  -webkit-align-items: start;
          align-items: start;
}
._2ufKIi {
  font-size: 12px;
  font-weight: bold;
}
._1imhnS {
  color: #ddd;
  margin-left: 6px;
  text-transform: lowercase;
}
._2_KXqq {
  font-size: 14px;
  color: #212121;
  margin-left: 6px;
  text-transform: uppercase;
}
.d1tJgO {
  width: 100%;
}
