._2K-jR5{
  font-weight: 14px;
  padding: 10px;
}
.pe5ARj {
  font-weight: bold;
  margin-right: 15px;
}
._2hRFJX > div {
    padding-left: 3px !important;
    margin-right: 10px;
  }
._1AdB4y {
  white-space: nowrap;
}
._2hkNlW {
  margin-left: 5px;
}
.isDuF_ {
  margin: -1px 5px 0 10px;
}
._2jVgVx {
  max-width: 240px;
}
._3b-ao8 {

}
._1Hmdhq .pe5ARj {
    margin-bottom: 10px;
  }
._1Hmdhq ._1AdB4y {
    margin-top: 10px;
  }
._21GNqM {
  padding: 0;
  margin-top: 15px;
}
._21GNqM ._2hRFJX:not(:last-child) > div {
      padding-left: 3px !important;
      margin-right: 6px;
    }
._21GNqM ._2hRFJX:first-child input {
      margin-left: 0;
    }
._21GNqM ._2hRFJX:last-child > div {
      margin-right: 0;
    }
._21GNqM ._3b-ao8 {
    color: #727272;
  }
._1qx35_ {
  border: none !important;
  padding: 0 !important;
}
