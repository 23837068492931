._2dxYN7 {
  cursor: pointer;
  white-space: nowrap;
}

.MNwL5Q {
  position: relative;
  top: 1.6px;
  left: 2px;
  margin-right: 6px;
  transition: all 0.2s ease;
}

._1kCWRH {
}

._2LoExH {
  text-align: center;
}

.ERuh51 ._2dxYN7 {
    white-space: normal;
  }

._93YbVK {
}

._13sv9O {
}

._2Ld408 {
  max-height: 240px;
  min-width: 86px;
  overflow-x: hidden;
  overflow-y: auto
}

._2Ld408::-webkit-scrollbar {
    width: 16px;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.5);
  }

/* GBT specific styles */

.corp-gbt ._2dxYN7:hover {
      background-color: rgba(0, 38, 99, 0.2);
    }

.corp-gbt ._1kCWRH {
    background-color: rgba(0, 38, 99, 0.2)
  }

.corp-gbt ._1kCWRH:hover {
      background-color: rgba(0, 38, 99, 0.2);
    }

.corp-gbt ._93YbVK {
    background-color: #002663
  }

.corp-gbt ._93YbVK:hover {
      background-color: #002663;
    }
