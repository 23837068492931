._3nVEig,
.XcW6EZ {
  margin-right: 5px;
}

  ._3nVEig span, .XcW6EZ span {
    padding-right: 5px;
  }

._3-2jHU {
  margin-bottom: 5px;
  margin-left: -5px;
}

._37LIPj {
  margin-top: 10px;
}

._13sB3Z {
  margin-left: 10px;
}

._16c9Xp {
  padding: 2px 10px;
}

._2vDQVB {
  padding-left: 6px;
}

._3FkvRk .XcW6EZ {
    margin-top: 8px;
  }

.f_4sKK {
  margin: -7px -6px;
}

/* GBT specific styles */

.corp-gbt ._19YXJb {
    font-weight: bold;
  }

.corp-gbt ._1esYjs {
    margin-left: -11px;
    color: #4d4f53;
  }

.corp-gbt ._16c9Xp {
    color: #002663;
    border-colo: #002663;
  }

.corp-gbt .f_4sKK svg * {
    fill: #2870d2;
  }
