._1k6nB5 {
  background: #FFFFFF;
  height: 700px;
  overflow: hidden;
  position: relative;
}

  ._1k6nB5 h1 {
    font-size: 30px;
    color: #000;
    font-weight: 300;
    margin: 30px 0;
    text-align: center;
  }

  ._1k6nB5 text {
    fill: #3071a9;
    font-size: 14px;
    dominant-baseline: central;
  }
._3r1WmI {
  position: relative;
  width: 100%;
  height: 95%;
}
._3r1WmI text {
    fill: #fff;
    opacity: 0;
    transition: opacity 0.2s ease-out
  }
._3r1WmI text._3cXZrV {
      opacity: 1;
    }
._3r1WmI rect {
    opacity: 0;
    transition: opacity 0.2s ease-out
  }
._3r1WmI rect._3cXZrV {
      opacity: 1;
    }
/**
 * @media
 */
@media all and (max-width: 1280px) {
  ._1k6nB5 {
    height: 650px;
  }

    ._1k6nB5 h1 {
      font-size: 24px;
    }
}
@media all and (max-width: 960px) {
  ._1k6nB5 {
    height: 500px;
  }

    ._1k6nB5 h1 {
      font-size: 18px;
    }
}
@media all and (max-width: 600px) {
  ._1k6nB5 {
    display: none;
  }
}
