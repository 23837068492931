._3nBDSm {
  margin: 10px 0 20px;
}

  ._3nBDSm td {
    padding: 15px;
  }

  ._3nBDSm th {
    padding: 15px;
    text-align: left;
  }
.jgvzYH:hover {
    background-color: #e3eef7;
  }
.jgvzYH td {
    text-align: right
  }
.jgvzYH td:last-child {
      text-align: left;
    }
._1gC1lO {
  margin: 20px 0;
  color: #727272;
}
