._1CHfzP {
  font-weight: bold;
  margin-right: 5px;
}

._3JMzy6 {
  font-size: 30px;
  line-height: 25px;
  margin-top: 6px;
  margin-right: 3px;
}
