.b-RTKv {
  width: 650px;
}

._3zXLqy:after {
    content: ':'
  }

.t42L5n {
  font-size: 18px;
  font-weight: bold;
}

._1kv98q {
  font-size: 20px;
  margin: 20px;
}

.EFQKIt {
  margin-top: 20px;
}

._1QRDSs {
  padding: 10px 20px;
}
