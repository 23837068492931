._1SMl3i {
  padding: 20px;
}

._2lBAuT {
  font-size: 45px;
  font-weight: bold;
  width: 100%;
}

.QRlSwT {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

._2GLy6k {
  width: 100%;
}

._1X7nz7 {
  min-width: 300px;
}
