._3PbjhK {
  border-right: 1px solid #eee;
  color: #212121;
  margin-top: 30px;
}

._1LDn_d {
  min-height: 30px;
}

._3EYiQw {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
}

._1Gov5P {
  font-size: 18px;
  font-weight: 300;
  margin: 10px 0;
}

._2Xgymk {
  cursor: pointer;
  margin: 5px 0;
}
