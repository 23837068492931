.YkIELe {
  color: #444;
}
._3vqD0S {
  position: relative;
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
}
._3vqD0S ._2uUvtJ {
    position: absolute;
    top: -20px;
    margin-left: 15px;
  }
@media (min-width: 960px) {
    ._3vqD0S:not(.Tf5IBJ) {
      -webkit-align-items: center;
              align-items: center;
      border-radius: 0;
    }

      ._3vqD0S:not(.Tf5IBJ) ._2uUvtJ {
        top: -50px;
        margin: 0;
        width: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto
      }
  }
.Tf5IBJ {
  border-radius: 0;
  min-width: 570px;
  max-height: 50vh;
  overflow-y: auto;
}
.Tf5IBJ ._2uUvtJ {
    top: inherit;
    margin-top: 20px;
  }
.HuaXB8 {
  position: initial;
  background-color: #fff;
}
.HuaXB8 ._2uUvtJ {
    position: inherit;
    top: inherit;
    margin-top: 20px;
  }
@media (min-width: 960px) {
    .HuaXB8 ._2uUvtJ {
      top: inherit;
    }
  }
/* GBT specific styles */
.corp-gbt .YkIELe {
    color: #4d4f53;
  }
.corp-gbt ._3vqD0S {
    background-color: transparent;
  }
