/* Utils */
._2FoH8W > * {
    display: block;
    border-top: 1px solid #e6e6e6
  }
._2FoH8W > *:first-child { border-top: 0; }
._2FoH8W > *:first-child:last-child { border-top: 0; }
._2hnAsy {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px 18px;
}
/* Top level container */
._2cqx55 {
  color: #212121;
  margin-top: 50px;
}
._34JyBb {
  background-color: #f9f9f9;
  border: solid 1px #e6e6e6 !important;
}
/* Provider info: logo, name, type */
._19JfML {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px;
}
._3gTFuX {
  margin-top: -50px;
}
._1jz1gP {
  display: block;
  width: 100px;
  border-radius: 50px;
  height: 100px;
  background-size: contain;
  border: 5px solid #e6e6e6;
  border-radius: 50px;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
  background-position: center;
}
._1JyTvw {
  margin-top: 10px;
  color: #212121;
  font-weight: bold;
  font-size: 16px;
}
._1nCPsc {
  font-size: 14px;
  font-weight: normal;
}
/* Passengers, bags, vehicles */
._2BQk_q {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  -webkit-align-items: center;
          align-items: center;
}
._2BQk_q > * {
    border-right: 1px solid #e6e6e6
  }
._2BQk_q > *:last-child { border-right: 0; }
._2BQk_q > *:last-child:first-child { border-right: 0; }
.hXZ7TS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  padding: 14px 8px 12px 8px;
  -webkit-align-self: stretch;
          align-self: stretch;
}
._2K6kY6 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
}
._2-CVqf {
  margin-left: 10px;
}
._3s6mx9 {
  width: 25px;
  height: 25px;
}
._1LwlUT {
  margin-top: 5px;
  position: relative;
  color: #727272;
  font-size: 11px;
  text-align: center;
}
/* Free wait time */
._3fFYFf {
}
._3ct0ZA {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  white-space: nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-align-self: stretch;
          align-self: stretch;
  padding: 12px;
}
._1j4CMl {
  width: 35px;
  height: 35px;
  fill: green;
  margin-right: 5px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
.GaXyxX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}
._3-fz5C {
  font-size: 14px;
  color: green;
  white-space: nowrap;
  line-height: 1em;
  margin-top: 7px;
}
._14808d {
  white-space: normal;
  font-size: 11px;
  line-height: 1em;
  color: #aaa;
}
._7iENf9 ._1j4CMl {
    fill: orange;
  }
._7iENf9 ._3-fz5C {
    color: orange;
  }
/* Gratuity */
._3KZ5X2 {
  cursor: pointer;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: stretch;
          justify-content: stretch;
  padding: 0;
}
._2hIWNB {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto
}
._2hIWNB:hover {
    background-color: #e6e6e6;
  }
._2_e2Rl {
  margin-left: 10px;
  color: green;
}
._2SNVzv {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  margin-left: 10px;
  text-align: right;
  color: green;
}
._1BYZy2 {
  border-top: 1px dashed #e6e6e6;
  white-space: nowrap;
  font-size: 12px;
  background-color: #FFFFFF
}
@media all and (max-width: 960px) {
._1BYZy2 {
    -webkit-justify-content: flex-start;
            justify-content: flex-start
}
  }
._3tsptc {
  margin-right: 5px
}
.Dujc8U {
  padding: 0 5px;
  cursor: pointer
}
.Dujc8U:first-child { padding-left: 0; }
.Dujc8U:last-child { padding-right: 0; }
.Dujc8U:last-child:first-child { padding: 0; }
._3Qclee {
  cursor: auto;
  cursor: initial;
}
._3Qclee ._2hIWNB:hover {
      background-color: transparent;
    }
/* Price row with not Total price, like ride price, vhicle price, etc */
._1AlDVk {
}
/* A block with original and new price */
._3LlH1D {
  background-color: #f9f9f9;
  border: solid 1px #e6e6e6;
  margin-top: 15px;
}
/* Coupon editor field */
._1AFAg0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.wbCnC9 {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
._1srT0e {
  height: 40px;
  outline: 0 !important;
  border: 0;
  border-left: 1px solid #e6e6e6;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  width: 70px;
  transition: width 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms
}
._1srT0e:focus { width: 110px; }
._2g4qde ._1srT0e {
    width: 110px;
  }
._3CsMiG ._1srT0e {
    color: red;
  }
.H9RniX ._1srT0e {
    color: green;
  }
._1srT0e::-webkit-input-placeholder {color: green; text-transform: uppercase; text-align: center;}
._1srT0e::-moz-placeholder          {color: green; text-transform: uppercase; text-align: center;}
._1srT0e:-moz-placeholder           {color: green; text-transform: uppercase; text-align: center;}
._1srT0e:-ms-input-placeholder      {color: green; text-transform: uppercase; text-align: center;}
._1srT0e:focus::-webkit-input-placeholder {color: transparent}
._1srT0e:focus::-moz-placeholder          {color: transparent}
._1srT0e:focus:-moz-placeholder           {color: transparent}
._1srT0e:focus:-ms-input-placeholder      {color: transparent}
/* Booking fee field */
._2SG3qF {
}
._2vKEp2 {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
._1wohSF {
  border-left: 1px solid #e6e6e6;
  padding-left: 10px;
  background-color: #FFFFFF;
  cursor: text;
}
.Yn424F {
  -webkit-flex: 0;
          flex: 0;
  height: 40px;
  outline: 0 !important;
  border: 0;
  font-weight: 500;
  padding-right: 18px;
  width: 72px;
  padding-left: 10px;
  text-align: right
}
.Yn424F:disabled {
    background-color: #f9f9f9;
  }
._2Bg8fI ._1wohSF {
    background-color: #f9f9f9;
  }
/* Amenity */
.v_23Hg {
}
._3Mos7N {
}
.gZNKXe {
  width: 18px;
  height: 18px;
  font-size: 18px;
}
._1NqHSM {
  display: -webkit-flex;
  display: flex;
}
._1Ixy1- {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
}
._1a3gKT {
  display: -webkit-flex;
  display: flex;
  padding: 8px;
  margin: -10px -2px -10px -12px;
  cursor: pointer;
}
._2eLvlL {
  cursor: pointer
}
._2eLvlL:hover {
    background-color: #e6e6e6;
  }
/* Total price below everything in breakdown */
._1MqejO {
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  position: relative;
}
._3_Dtym {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}
._30cM9G {
  font-weight: 600;
  -webkit-flex: 0;
          flex: 0;
  min-width: 100px;
}
._2hZLvu {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  font-size: 32px;
  line-height: 34px;
  font-weight: 400;
  text-align: right;
}
.lYqq9G {
  width: 100%;
  text-align: right;
  margin-top: 15px;
  font-size: 10px;
  color: #b6b6b6;
}
._2bPDex {
  font-size: 24px;
  position: absolute;
  bottom: 12px;
  left: 18px;
}
