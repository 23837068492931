.RR6hy5 {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
}
._9VM1tm {
  min-width: 100%;
  max-width: 100%;
  padding: 20px;
  box-shadow: none;
}
._2giX77 {
  font-size: 18px;
  color: #002664;
}
._nddyx {
  padding-top: 20px;
  font-size: 16px;
}
._nddyx a {
    color: #009bbb;
  }
