._2oANUF {
  padding-bottom: 25px;
}
._11Aixp {
  width: auto;
}
._2i3Y9Z {
  padding-top: 0 !important;
}
._3dJVxL {
  margin: 0 0 0 20px;
  color: #b6b6b6;
  border-color: #b6b6b6 !important;
  background-color: #f9f9f9;
}
._26WKeR {
  fill: #b6b6b6 !important;
  margin-left: 2px;
}
@media all and (max-width: 600px) {
  ._3dJVxL {
    margin: 10px auto 0;
  }
}
