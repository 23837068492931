._3Sq1da {
  padding: 20px;
}

._2fEqfF {
  font-size: 45px;
  font-weight: bold;
}

._3CWw7I {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-top: 20px;
}
