._26ahId {
  margin-bottom: 10px;
}

._324tPs ._27P7j_ {
    color: rgb(255, 156, 0);
  }

._11DQxc ._27P7j_ {
    color: rgb(0, 146, 69);
  }

._1GDKUz {
  font-size: 11px;
}

._27P7j_ {
  font-weight: bold;
}

._1heFGZ {
  font-weight: bold;
  color: rgba(111, 111, 111);
}
