._24wQ57 {
  display: none;
}
._2hB-6p {
  fill: #3071a9;
  font-size: 14px;
  font-family: inherit;
  text-transform: uppercase;
  dominant-baseline: central;
}
.Blit1V {
  fill: #FFFFFF;
}
.E-k2gE polygon,
  .E-k2gE path {
    fill: #0D84EB;
  }
