._3ZQpFz {

}
._3zZxei {
}
._2Bvry6 {
  padding: 45px 0;
  max-width: 920px;
  min-width: 554px;
  overflow: hidden
}
@media (max-width: 960px) {
._2Bvry6 {
    padding: 45px 20px
}
  }
._12z0ka, ._3SFPjx {
  height: 14px;
}
._3-AR5D {
  height: auto;
}
._3-AR5D._3KWuAz ._2V8PJ5 {
  line-height: 25px;
}
._222em9 {
  margin-right: 12px;
}
._222em9 img {
    margin-bottom: 2px;
    margin-left: 1px;
    max-width: none;
    max-width: initial;
  }
._3tq5X0 {
  white-space: nowrap;
}
._3tq5X0, ._3SFPjx {
  margin-top: 10px;
  font-size: 12px;
}
._1mEWjm {
  fill: #0D84EB;
  margin-right: 5px;
  display: inline-block;
  font-size: 14px !important;
  height: 14px !important;
}
._1mEWjm svg {
    width: 14px !important;
    height: 14px !important;
  }
._1mEWjm:last-child {
     margin-right: 0;
  }
.UhWibm {
  width: 16px;
}
.UhWibm svg {
    width: 16px !important;
  }
._2V8PJ5 {
  display: -webkit-flex;
  display: flex;
  padding-left: 20px;
  font-size: 14px;
  white-space: nowrap;
}
._2V8PJ5:last-child {
  padding-right: 0;
}
/* Copyright */
._308Ja_ {
  font-size: 12px;
  color: #727272;
  margin-top: 4px;
}
.Nq2r5F {
  margin: 0 10px 0 0;
  text-decoration: underline;
}
._2jAJxg {
  display: inline;
}
/* Mobile footer */
.ffFBBU {
  text-align: center;
  padding: 40px 10px 10px 10px
}
.ffFBBU > *:not(:last-child) {
    margin-top: 10px;
  }
.ffFBBU > *:last-child {
    margin-top: 5px;
  }
.ffFBBU ._3-AR5D {
    text-align: center;
  }
.ffFBBU ._2V8PJ5 {
    font-size: 12px;
    padding-right: 8px;
  }
.ffFBBU ._308Ja_ {
    text-align: center;
    width: 250px;
  }
