.OTRbb6 {

}

._2vGzvG {
  color: white;
  font-size: 12px;
  padding: 5px 8px;
  background-color: rgb(63, 153, 82);
  margin-right: 10px;
}

._1ZTG1F {
  width: 34px;
  margin: 0 !important;
}

._1ZTG1F ._2vGzvG {
    color: white;
    font-size: 10px;
    padding: 3px;
    width: 100%;
    min-height: 33.33%;
    -webkit-flex: 1;
            flex: 1;
    margin-right: 0;
  }

._1ZTG1F ._2vGzvG svg {
      fill: white;
      width: 18px;
      height: auto;
    }

._1DvFgn {
  height: 16px;
  width: 16px;
}

._1DvFgn svg {
    font-size: 20px;
    margin: -2px;
  }

/* Used for mobile Trip Details screen. */

._4Kes7z {
  margin-bottom: 0;
}

._4Kes7z ._2vGzvG {
    padding: 2px 5px;
    font-size: 11px;
  }

._2UsxSq {
  background-color: rgb(252, 191, 18);
}

.gAIvty {
  background-color: rgb(234, 88, 54);
}

._3801ZB {
  background-color: rgb(234, 88, 54);
}

._1gz3Ba {
  background-color: rgb(140, 198, 63);
}

/* GBT specific styles */

.corp-gbt ._2vGzvG {
    background-color: #008767;
  }

.corp-gbt ._3y5imA {
    background-color: #2870d2;
    color: white;
  }

.corp-gbt ._3y5imA svg {
      font-size: 14px;
    }

.corp-gbt ._2_YEoW {
    background-color: #002663;
    color: white;
  }

.corp-gbt ._1oZDbj {
    background-color: white;
    color: #002663;
    border: 1px solid #002663;
    height: 26px;
  }

