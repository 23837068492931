._3CQKry {
  padding: 30px;
  text-align: center;
  background-color: #002664;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
}
._47U7df {
  color: #FFFFFF;
  font-size: 28px;
  margin-bottom: 20px;
}
._1ltyMk {
  padding: 40px;
}
._2NXkrI,
._3OZC8B {
  text-align: center;
  font-size: 18px;
}
._24ZR1g {
  padding: 0;
}
._24ZR1g ._2NXkrI,
  ._24ZR1g ._3OZC8B {
    padding: 40px;
  }
