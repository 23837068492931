._3P4DqI {
  display: table;
  width: 100%;
  max-width: 600px;
  margin: -15px 0;
}
.JnPBs0,
._1rzei8 {
  display: table-cell;
  vertical-align: middle;
}
.JnPBs0 {
  position: relative;
  width: 90px;
  padding-top: 15px
}
.JnPBs0:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: #4D4F53;
  }
._1rzei8 {
  position: relative;
  padding: 65px 0 80px
}
._1rzei8:before,
  ._1rzei8:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #ADAFAF;
    opacity: 0.5;
  }
._1rzei8:before {
    top: 40px;
  }
._1rzei8:after {
    bottom: 40px;
  }
._3VLXHb {
  position: relative;
  width: 24px;
  height: 24px;
  margin: auto;
  border: 1px solid #4D4F53;
  border-radius: 24px;
  background-color: #FFFFFF;
}
._3VLXHb i {
    margin: 3px 4px;
    font-size: 15px;
  }
._3VLXHb svg {
    margin: 6px;
  }
._3lVLDy {
  width: 10px;
  height: 10px;
  color: #4D4F53;
  fill: #4D4F53;
}
._3nr020,
._2XPCT0,
._2GtRg5 {
  color: #8B8D8E;
  font-size: 12px;
  font-weight: 100;
}
._3nr020 {
  text-transform: uppercase;
}
._2XPCT0 {
  color: #4D4F53;
  font-weight: 500;
}
._1DkBHS {
  margin-top: 15px;
}
._1Zklx9,
._1C3vaP,
._2GtRg5 {
  display: inline-block;
  vertical-align: middle;
}
._1Zklx9 {
  font-size: 16px;
  color: #4D4F53;
}
._1C3vaP {
  font-size: 16px;
  margin: 0 15px;
  color: #4D4F53;
}
._2GtRg5 {
  margin-left: 30px;
}
._29sKGB .JnPBs0 {
      width: 60px;
    }
