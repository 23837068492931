._2YOVr7 {
  margin-top: 40px;
  margin-bottom: 20px;
}

.XJ67Ur:not(:last-child) {
    margin-bottom: 20px;
  }

._3aEegl {
  margin-top: 20px;
}

.iegxhg {
  margin-bottom: 10px;
}

._26dlmO {
}

._3oXqyO {
  margin-right: 10px;
}
