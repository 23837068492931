._39s-iw {
  margin-top: 0;
}

.desktop ._39s-iw {
    padding-right: 10px;
  }

.desktop ._3-Fvfv {
    padding-top: 32px;
  }

._18WF-3 {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}
