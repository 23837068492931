._37JIfW {
  outline: none;;
  margin: 6px;
  min-width: 13px;
  min-height: 13px;
}

._3NgrfM {
  padding: 3px 20px;
  cursor: pointer;
}

/* Modifiers */

.ofNAdA {
}

.cwH1fq ._37JIfW {
    margin: 2px;
  }

.cwH1fq ._3NgrfM {
    padding: 0;
  }

._2NDJdR {
  width: 100%;
}

._3dO-Xf {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}

/* GBT specific styles */

.corp-gbt ._37JIfW {
    position: relative;
    outline: none;
    margin: 6px;
    min-width: 14px;
    min-height: 14px;
  }

.corp-gbt ._37JIfW:checked::before {
    border: 2px solid #009bbb;
  }

.corp-gbt ._37JIfW:checked::after {
    background: #009bbb;
  }

.corp-gbt ._37JIfW::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: all 0.1s ease;
    border: 1px solid #4d4f53;
    background: #fff;
  }

.corp-gbt ._37JIfW::after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 50%;
    transition: all 0.1s ease;
  }

.corp-gbt ._3NgrfM {
    padding: 3px 2px;
  }
