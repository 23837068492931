.IKNQRS {
  border: 1px solid transparent;
  border-left: 4px solid #ff5722;
  border-radius: 3px;
  padding: 40px 40px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.45);
  max-width: 60%;
  margin: 0 auto;
}
.LErlNH {
  color: #4D4F53;
  font-size: 24px;
}
._1HRON8 {
  font-size: 16px;
  font-weight: 100;
}
._2q1mqb {
  margin: 40px auto 0;
  padding: 10px 80px;
}
.mobile .IKNQRS {
    max-width: 80%;
  }
.mobile ._2q1mqb {
    padding: 10px 40px;
  }
