._1pTf0w {
  display: inline-block;
  max-width: 550px;
  min-width: 100%;
  padding: 20px;
}

.trIVQi {
  border-radius: 0;
}
