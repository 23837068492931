._3wBXiU {
  margin-bottom: 40px;
  -webkit-flex-grow: 0;
          flex-grow: 0;
}

._3WtvXA {
  height: 100px;
  font-size: 36px;
  font-weight: 300;
  padding: 0 20px;
  line-height: 36px;
}

._3-lDEX {
  height: 40px;
  font-size: 14px;
  line-height: 14px;
  padding: 0 20px
}

._2fypcP {
}

.tQOgeU {
  background-color: #444444;
  color: #fff;
}
