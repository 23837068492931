._1CD-aD {
  margin-top: 40px;
  max-width: 40%;
  min-width: 380px;
  padding: 40px 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  border: transparent;
  border-radius: 4px;
}
._1CD-aD._1BnAZO {
  min-width: 100%;
}
._3fFFv3 {
  margin-bottom: 20px;
}
._3iD29m {
  margin-top: 40px;
  color: #4d4f53;
}
._3XPuNe,
._2Bn1qL {
  color: #009bbb;
}
._2f2BzT {
  margin-top: 20px;
}
._2Bn1qL {
  margin-top: 40px;
}
._1pUOU1 {
  border-radius: 3px;
}
._27ydFj {
  display: inline-block;
  color: #009bbb;
}
