._38erAT {
  padding: 100px;
  background-color: #0D84EB;
}
._3ix0NA {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
}
.GEUvUp {
  margin: 0 auto;
  float: none;
}
.GEUvUp img {
    height: 48px !important;
    width: 120px;
  }
._1T5jUR {
  margin: 40px 0 45px;
  padding: 0 35px;
  font-size: 20px;
}
.mCGkxK {
  margin-top: 50px;
}
._2wrFsG span {
    margin-right: 30px
  }
._2wrFsG span:last-child {
      margin-right: 0;
    }
.w39ie1 {
  margin: 20px 0 25px;
  border-radius: 3px;
}
._1ePRBv {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._1ePRBv ._3ix0NA {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
._1ePRBv .mCGkxK {
    display: none;
  }
.corp-renault ._38erAT {
    background-color: #FFFFFF;
  }

