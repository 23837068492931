*,
*:before,
*:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html, body {
  background: #fff;
  font: 400 16px/1.4 Manrope, Tahoma, Verdana, Arial, sans-serif;
  color: #212121;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: revert;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: revert;
  font-weight: revert;
}

h1 {
  font-size: 36px;
  font-weight: 300;
}

h2 {
  font-size: 20px;
  font-weight: 300;
}

#container {
  height: 100%;
}
