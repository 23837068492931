._3D2ouO {
  position: relative;
  padding: 5% 2.5% 5% 2.5%;
  padding: 25px
}
@media all and (max-width: 960px) {
._3D2ouO {
    padding: 15px 10px
}
  }
._2sYRlk {
  position: relative;
  color: #fff;
  max-width: 750px;
  margin: 0 auto;
}
._2sYRlk h2 {
    margin: 0 0 45px;
  }
@media all and (max-width: 960px) {
._2sYRlk {
    text-align: center
}
  }
._29SLec {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  background-size: cover
}
@media all and (max-width: 1280px) {
._29SLec {
    background-size: 1280px auto
}
  }
._29SLec:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
