/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

._1jxnmN {
  z-index: calc(6000 + 1);
}

._2I7l9i {
  max-width: 950px;
  width: 100%;
  border: none;
}

