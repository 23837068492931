._3jqOKv {
  max-width: 40%;
  min-width: 280px;
}

._2JDwYl {
  padding-top: 40px;
}

.jSmpjC {
  border-radius: 0;
}

._3vfcXp {
  border-radius: 0;
}

._2YG74A {
}
