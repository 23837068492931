/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

._2mwzj0 {
  position: relative;
}

._3oHRJY {
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  z-index: 5000;
}

._13LYtA {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  -moz-transform: translate(-50%,-50%);
}

._2EPA_z {
  width: 100%;
  height: 100%;
  position: absolute;
  /* Default values, overwrite with custom class */
  bottom: -10px;
  right: -10px;

}

._2xemLe {
  border-radius: 50%;
}
