._1gstb1 {
  padding: 100px;
  background-color: #0D84EB;
}
._3PZYIc {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
}
.fAnCN6 {
  margin: 0 auto;
  width: 100%;
}
.fAnCN6 img {
    height: 48px !important;
    width: 120px;
  }
._3UBvIG {
  margin: 40px 0 45px;
  padding: 0 35px;
  font-size: 24px;
  color: #212121;
}
._1qUB0a {
  margin-top: 50px;
}
._2tMNO4 span {
    margin-right: 30px
  }
._2tMNO4 span:last-child {
      margin-right: 0;
    }
.E_fHYW {
  margin: 20px 0 25px;
  border-radius: 3px;
}
._1hkg2S {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._1hkg2S ._3PZYIc {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
._1hkg2S ._1qUB0a {
    display: none;
  }
.corp-renault ._1gstb1 {
    background-color: #FFFFFF;
  }
