.lLE8OL {
  margin-bottom: 6px;
}

._3btdEA svg {
    width: 16px;
    height: 16px;
  }

.lSOBoV {
  margin-left: 8px;
  font-size: 16px;
}