.SapqGS {
  padding: 100px;
  background-color: #FFFFFF;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
._3Yu8TQ {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
}
._3fTX1d {
  margin: 0 auto;
  float: none;
}
._3fTX1d img {
    height: 45px !important;
  }
._3ixKCo {
  margin: 40px 0 10px;
  padding: 0 35px;
  font-size: 20px;
}
._3ixKCo a {
    color: #0D84EB;
  }
._2I8mz8 {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._2I8mz8 ._3Yu8TQ {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
