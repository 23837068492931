.U7rXbR {
  background: #FFFFFF;
  z-index: 1;
  border-radius: 3px;
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.45);
}
._84vuCw {
  padding: 52px 30px 0 30px;
  text-align: center;
}
/* Important for IE */
._312ZOh {
  width: 100%;
  max-width: 474px;
}
._3qByp6, ._1_x2LQ {
  margin-bottom: 20px;
}
._1_x2LQ {
  font-size: 24px;
}
._1bL75a {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 40px;
}
._2yTdNw {
  width: 100%;
  padding: 20px 30px;
  border-top: 1px solid #ddd;
  text-align: right;
}
._2MT003 {
  font-size: 18px;
  font-weight: 500;
}
.mobile ._1_x2LQ {
    display: none;
  }
.mobile ._2yTdNw {
    border-top: none;
    padding: 10px;
  }
.mobile ._2MT003 {
    width: 100%;
  }