._3koAPQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: stretch;
          align-items: stretch;
  margin-top: 30px;
}
.Tx8Z6d {
  padding: 15px 0;
}
._39HscX {
  color: #212121;
  font-size: 14px;
  margin-left: 10px;
}
._9M7HbF {
  margin-top: 10px;
}
._3dOFtl {
  background: #FFFFFF;
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  padding: 10px 0 20px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
}
._3dOFtl._27pIc2 {
  padding: 20px;
}
.ZdeiNs {
  font-weight: bold;
}
._3JuJmQ {
  width: 35px;
  height: 35px;
  fill: #0D84EB;
  margin-bottom: 20px;
}
._2deS0O {
  margin-top: 20px;
}
._2ao241 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 20px;
  margin-top: 20px;
}
._2bXC9H {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #E6E6E6;
  border-left: none;
  border-right: none
}
._2bXC9H::-webkit-scrollbar {
    width: 6px;
  }
._2bXC9H::-webkit-scrollbar-thumb {
    background: #0D84EB;
  }
.tqIf56 {
  border: 1px solid #E6E6E6;
  border-left: none;
  border-right: none;
  padding: 0 25px 0 20px;
}
._1QvmNu {
  cursor: pointer;
  border: none;
}

