._3Bx4gU {
  max-width: 440px;
  min-width: 100%;
  padding: 20px;
}

.ORwxMN {
  text-align: center;
  min-height: 21px;
}

._720y1j {
  padding-top: 40px;
}

._3y6wF_ {
  border-radius: 0;
}

._3aKoOa {
  border-radius: 0;
}

.LCF3c2 {
}

._2ppxBi {
  height: auto;
}
