._1Bu0k2 {
  padding-top: 10px;
  text-align: center;
}
  ._1Bu0k2 span:first-child {
    color: #b2b2b2;
  }

._1qeRjR {
  font-weight: bold;
  font-size: 16px;
}
