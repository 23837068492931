._1Y9dTJ {
  margin-top: 60px;
  max-width: 50%;
  min-width: 380px;
  padding: 20px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
}
._1utoGf {
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
  color: #002664;
}
