._3UYhzA {
  padding: 20px;
  background: #006890; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #006890 0%,#009bbb 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006890', endColorstr='#009bbb',GradientType=0 ); /* IE6-9 */
}
.btLC02 {
  padding: 30px 0;
  text-align: center;
}
._35eUuR{
  color: #FFFFFF;
  font-size: 13px;
  display: inline-block;
  margin: 5px 0;
  padding: 0 20px;
  text-transform: uppercase;
  border-right: 1px solid #fff;
}
._35eUuR a {
    color: #FFFFFF
  }
._35eUuR a:hover {
      text-decoration: underline;
    }
._35eUuR:last-child {
    border-right: none;
  }
.tKz1N2 {
  max-width: 900px;
  margin: 0 auto;
  color: #B7DBE6;
  text-align: center;
}
