._2Zni6p {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

._3k-BCT {
  position: relative;
  width: 100%;
  max-width: 1500px;
  padding: 5% 2.5% 8% 2.5%;
}

._3k-BCT.DIEN-y {
  padding: 5% 4% 5% 4%;
}

._3oajxZ {
  color: #0D84EB;
  font-weight: bold;
  margin: 20px 0 40px 0;
}

._3oajxZ.DIEN-y {
  text-align: center;
  line-height: 1;
}

.waOME6 {
  color: #8b8d8e;
  margin-bottom: 20px;
}
