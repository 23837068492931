/* Basic style */
._1NFB0N {
  text-decoration: none;
  cursor: pointer
}
._1NFB0N:hover{
    text-decoration: underline;
  }
/* Coloring */
.weib-d {
    color: #0D84EB;
    color: var(--mz-primary-color, #0D84EB);
    transition: all 0.3s ease
  }
.weib-d:hover {
      -webkit-filter: brightness(.90);
              filter: brightness(.90);
    }
.weib-d:active {
      -webkit-filter: brightness(.80);
              filter: brightness(.80);
    }
.M2Y-nx {
    color: #727272;
    transition: all 0.3s ease
  }
.M2Y-nx:hover {
      -webkit-filter: brightness(.90);
              filter: brightness(.90);
    }
.M2Y-nx:active {
      -webkit-filter: brightness(.80);
              filter: brightness(.80);
    }
/* Modifiers */
._2rNkiK {
  text-transform: uppercase;
}
