._1BvdTi {
  width: 100%;
  max-width: 660px;
  margin-top: 25px;
  border-top: 1px solid #ADAFAF;
  text-align: center;
}
._3EIwKy {
  font-size: 18px;
  padding: 30px 0;
}
._3nPH8w {
  display: block;
}
._2XWrmB {
  display: inline-block;
}
