._1cSTfl {
  padding: 40px 20px 20px 20px;
  max-width: 40%;
  min-width: 380px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  border-radius: 3px;
}
._3r_nvJ {
  margin-bottom: 20px;
}
._3D3HIh {
  color: #009bbb;
}
