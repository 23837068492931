._2P2eTw {
  position: relative;
}
/* Where the address goes */
.X6Sl1b {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 3px #ddd;
  border-bottom: 1px solid #ddd;
  height: 55px;
  line-height: 55px
}
.X6Sl1b ._3ajdho:empty {
    display: none;
  }
._3ajdho {
  max-width: 920px;
  width: 70%;
  font-size: 14px
}
@media all and (max-width: 600px) {
._3ajdho {
    width: 100%
}
  }
.oPXzIh {
  padding-right: 10px;
  border-right: 1px solid #eee
}
@media all and (max-width: 600px) {
.oPXzIh {
    padding-left: 10px
}
  }
._3g5CZ3 {
  padding-left: 10px;
}
.wyix6X {
  margin-left: 5px;
  overflow: hidden;
  color: #444;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
}
.zUR6pu {
  min-height: 300px;
  overflow: hidden;
  width: 100%;
  position: relative
}
.zUR6pu > div {
    position: static !important;
    position: initial !important;
  }
.zUR6pu > ._2XVhna {
    position: absolute !important;
  }
.QrsJmR {
  width: 100%;
  height: 100%;
  font-size: 24px;
  margin-top: 40px;
}
._2XVhna {
  height: 100%;
  width: 100%;
}
