._25760L {
  line-height: 150%;
  padding-left: 20px;
  list-style-type: disc;
}

._28a2Qk {
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 3px;
}

._2I1lPN {
  font-weight: bold;
  margin-bottom: 3px;
}
