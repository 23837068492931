._1c6cRA {
  font-size: 10px;
  color: #b2b2b2;
  min-height: 85px;
}

._1gWSxf {
  color: #999;
  background-color: white;
}

._1iL0Vr {
  font-size: 14px;
  padding-bottom: 4px;
  white-space: nowrap
}

._1IDNs7 ._1iL0Vr {
    color: #444;
  }

._3rK4nn,
._23hXTV {
  color: #444;
}

._3rK4nn {
  font-size: 21px;
  line-height: 28px;
}

._23hXTV {
  font-size: 28px;
  line-height: 28px;
}

._1JlOhd {
  text-align: justify;
  margin-top: 3px;
}

._1cZSpr {
  font-weight: bold;
}

._1pUm_M {
  margin: 0 10px;
  background-color: transparent;
}
