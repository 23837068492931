._2Dt6dw {
  padding: 0 10px;
  position: relative;
}

._2ubKaa {
  margin-top: -40px;
  margin-bottom: 40px;
}

._1GUxu2 {
  text-align: center;
}

._50sDj7 {
  height: auto;
  text-align: center;
  padding: 10px 15%;
}
