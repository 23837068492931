/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

._6m2Hrp {
  width: 100%;
  margin-bottom: 3rem;
}

._3zMjEI {
  font-size: 24px;
}

._2MODRi {
  width: auto;
  height: auto;
  font-size: 14px;
  white-space: normal;
}

._2Tibpk {
  width: 300px;
  padding: 20px;
  border-radius: 3px;
  border: solid 1px transparent;
}

.rcSDel {
  font-weight: bold;
  margin: 0 5px 0 0;
}

.a9Syzq {
  margin-right: 10px;
  margin-top: 3px;
  width: 24px;
  height: 24px;
}

.an9Iu0 {
  margin-left: 10px;
  padding-top: 2px;
  text-align: center;
  vertical-align: middle;
}

._1WHHtZ {
  background-color: white;
  font-size: 24px;
  height: 40px;
  padding: 0 20px;
  margin-bottom: 1rem;
}

.V7S7fj {
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 16px;
}
