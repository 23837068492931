._1Z97LB {
  position: relative;
}
._2OqRLq {
  margin-top: 40px;
}
@media all and (max-width: 959px) {
  ._2MWxNV {
    display: block;
    padding: 0 20px;
  }
  ._1Z97LB {
    width: 100%;
  }

  .qMf8i6 {
    padding-top: 20px;
    display: none;
  }

  ._29eVKe {
    margin-bottom: 40px;
  }
}
@media all and (min-width: 960px) {
  ._1Z97LB {
    width: 670px;
    max-width: 670px;
    padding-right: 40px;
  }

  ._2MWxNV {
    /* Important for IE */
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
  }

  .qMf8i6 {
    width: 250px;
    max-width: 250px;
    padding-top: 48px;
  }

  ._29eVKe {
    display: none;
  }
}
