.mvrDiY {
  white-space: normal;
}

.HR8vME {
  display: block;
  padding: 2px 5px;
  font-weight: 400;
  border-radius: 3px;
  margin: 1px 0 0;
}

._2qFU33 {
}

._3hesdp {
}

input:focus ~ .HR8vME {
  display: none;
}
