._3DwU_z {
  padding: 0 5% 5%;
}

._2PZjK4 {
  margin: 20px 0;
  padding: 10px;
}

._29h7LL {
  font-size: 28px;
}

.BKJI5k {
  font-size: 20px;
  margin-left: 5px;
}

._3kqVYo {
}

._31JN_H {
  list-style-type: disc;
  list-style-position: inside;
}

._31JN_H li {
    margin: 10px 0;
  }

._1rEXOI {
  padding: 4% 6% 10%;
}

._1YEEoU {
  margin-bottom: 20px;
}

._3GRNa0 {
}

.R5TrLG {
  padding-right: 40px;
}

._150JFY > * {
  margin-left: 5px;
}

.X1YiwM {
}

._1blEo5 {
  padding: 20px 40px;
}

.znkzbo {
  margin-right: 20px;
}
