.XXgzBE ._21BKqC {
  -webkit-flex-direction: row;
          flex-direction: row;
}
._21BKqC {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  margin-left: 130px;
}
._3SwanW ._21BKqC {
    margin-left: 0;
    margin-top: 10px;
  }
@media (min-width: 960px) {
    :not(.XXgzBE) ._21BKqC {
      margin-top: 60px;
      margin-left: 5px;
    }
  }
._3SwanW .ylVNCm {
    -webkit-align-items: center;
            align-items: center;
  }
@media (min-width: 960px) {
    :not(.XXgzBE) .ylVNCm {
      -webkit-align-items: center;
              align-items: center;
    }
  }
._2gMOCR {
  margin-top: 20px;
}
._2LgiOV {
  margin-right: 20px
}
@media (min-width: 960px) {
._2LgiOV {
    margin-right: 0
}
  }
._3SwanW ._2LgiOV {
    margin-right: 0;
  }
.XXgzBE ._2LgiOV {
  margin-right: 10px;
}
.Qi7v-Q {
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-bottom: 5px
}
@media (min-width: 960px) {
    .Qi7v-Q:not(.XXgzBE) {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      margin: 0;
    }
    .Qi7v-Q ._61Tpo7, .Qi7v-Q ._1LDrv2  {
      display: block;
    }
  }
@media (max-width: 960px) {
    .Qi7v-Q ._61Tpo7, .Qi7v-Q ._1LDrv2  {
      display: none;
    }
  }
.Qi7v-Q._3SwanW {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch;
    margin-bottom: 0;
  }
.Qi7v-Q._3SwanW ._61Tpo7, .Qi7v-Q._3SwanW ._1LDrv2 {
      display: none;
    }
.Qi7v-Q.XXgzBE {
    min-height: 94px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
.Qi7v-Q.XXgzBE ._61Tpo7, .Qi7v-Q.XXgzBE ._1LDrv2 {
      display: none;
    }
._1er4Jv {
  width: 100%;
  color: #444;
  background-color: #f9f9f9
}
@media (min-width: 960px) {
    ._1er4Jv ._61Tpo7, ._1er4Jv ._1LDrv2 {
      display: none;
    }
  }
@media (max-width: 960px) {
    ._1er4Jv ._61Tpo7, ._1er4Jv ._1LDrv2 {
      display: block;
    }
  }
._1iTymp {
  border-bottom: 1px solid #e6e6e6;
}
._1er4Jv._10CZEd {
  border: 1px solid #e6e6e6;
}
._1er4Jv._10CZEd.XXgzBE {
  border: none;
  margin-top: 30px;
}
.XXgzBE {
}
._1er4Jv.XXgzBE > :first-child {
    margin-top:10px;
  }
.zmNIBl {
  background-color: inherit;
  margin-left: 0;
  margin-right: 0;
  padding: 10px 20px
}
@media (min-width: 960px) {
.zmNIBl {
    margin-left: 0;
    margin-right: 0
}
  }
._3SwanW .zmNIBl {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    margin-right: 0;
  }
.XXgzBE .zmNIBl {
    padding-top: 10px;
    padding-bottom: 10px;
  }
._2gMOCR ._61Tpo7,
._1er4Jv ._61Tpo7 {
  border: 1px solid #e6e6e6
}
._2gMOCR ._61Tpo7 *, ._1er4Jv ._61Tpo7 * {
    background: #f9f9f9;
  }
._2gMOCR ._61Tpo7 > div > div, ._1er4Jv ._61Tpo7 > div > div {
    padding: 20px 10px;
    margin: 0;
  }
._2gMOCR ._61Tpo7 {
  border-bottom: none;
}
._1er4Jv ._61Tpo7 {
  border-left: none;
  border-right: none;
}
._1LDrv2 {
  border-bottom: 1px solid #e6e6e6;
}
