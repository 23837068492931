.b2JZUh {
  position: relative;
  width: 100%;
  max-width: 660px;
  padding: 25px 60px 15px;
  margin-bottom: 30px;
  background-color: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}
._14Toqg,
.F65BbX {
  width: 100%;
  display: table;
}
._14Toqg {
  margin-bottom: 10px;
}
.s0fpY0,
._2aZw78 {
  width: 50%;
  display: table-cell;
  vertical-align: top;
  font-size: 16px;
  color: #606266;
  font-weight: 100;
}
.s0fpY0 span, ._2aZw78 span {
    color: #002663;
    font-weight: 400;
  }
.s0fpY0 {
  text-transform: capitalize;
}
.muGDoa {
  padding: 25px;
}
.muGDoa .s0fpY0,
  .muGDoa ._2aZw78 {
    width: 100%;
    display: block;
  }
.muGDoa .s0fpY0 {
    margin-bottom: 10px;
  }
