._3vy3uh {
}

._3fpXYN ._2X7myv {
    color: #009245;
  }

._2lMhfR ._2X7myv {
    color: #ff9c00;
  }

._2c2E5p {
  margin-top: 3px;
}

._2X7myv {
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
}

.F8jMJO {
  color: rgb(160, 160, 160) !important;
}

.mscqx5 {
  font-size: 16px;
  margin: 0 3px;
  color: rgb(160, 160, 160) !important;
}

._3vooOU {
  padding: 15px 10px;
  border-radius: 3px;
  background-color: #eeeeee;
  margin-top: 8px;
  color: #888;
}

/* GBT specific styles */

.corp-gbt ._3fpXYN ._2X7myv {
      color: #002663;
    }

.corp-gbt ._2lMhfR ._2X7myv {
      color: #009bbb;
    }
