.VYC1s7 {
  width: 100%;
  outline: none;
  font-size: 14px;
  color: #444444;
  padding: 2px;
  font-family: inherit;
}

.bKL1DT {
  resize: none;
}
