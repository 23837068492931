._1S7Utu {
  margin-right: 10px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

._1S7Utu._10Xc83 {
  /* important for IE 11 */
  height: 45px;
}

._3f_6hp {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 3px;
}

._1Gkxm1 {
  font-size: 14px;
}

._2XrBag {
  font-size: 16px;
}

._2ZYZwt {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

._2ZYZwt svg {
    width: 18px;
    height: 18px;
  }
