._3gf-DU {
  width: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
._3ewr4C {
  overflow: auto;
}
/* Date selector */
._2tSus5 {
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
._4qkFpW {
  font-size: 19px;
  font-weight: 500;
  padding: 10px 0;
}
._2A4_3T {
  width: 40px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 10px 0;
}
._1r26F9 {
  padding: 0 10px;
  margin-bottom: 20px;
}
._3fuFV6 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 11px;
}
._3S1M0r {
}
._3N3geL {
  padding: 9px;
}
._6SvG8o {
}
/* Time picker */
._14ZajC {
  padding: 10px 0;
}
._3NCEqa {
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 10px 0;
}
._2zLjEK {
  -webkit-appearance: none;
          appearance: none;
  outline: none;
  border: none;
  padding: 5px 30px 5px 30px;
  border-radius: 0;
  font-size: 16px;
}
._2gMcFD {
  position: relative
}
._2gMcFD:after {
    pointer-events: none;
    content: '';
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    width: 6px;
    height: 6px;
    position: absolute;
    top: 50%;
    right: 13px;
    margin-top: -4px;
  }
/* Buttons */
._3vZtkR {
  padding: 10px 10px 0 10px;
  border-bottom: none;
}
._3AbW-s {
  margin-bottom: 10px;
  border-width: 1px !important;
}
