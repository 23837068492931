._1W7K8i {
  padding: 5px 5px;
  border-radius: 4px;
  cursor: pointer;
  max-width: 100%;
}
._3RjCsq,
._1W7K8i:hover {
  color: #727272;
  background: #EEE;
}
._1W7K8i._3v9J0F {
  color: #FFFFFF;
  background: #0D84EB;
}
