._1UcMMT {
  text-transform: uppercase;
}
._2RCSRF {
  font-size: 24px;
  font-weight: 300;
}
._2aReLK {
  padding: 20px 5px 5px 40px;
}
._2Z4DVa {
  min-height: 50px;
  padding: 5px 10px
}
._2Z4DVa:hover {
    background-color: #e3eef7;
  }
._7P13rO {
  margin-top: 10px;
}
._12gLKF {
  margin-left: 10px;
  margin-right: 40px;
}
._1Nhbx9 {
  margin: auto;
}
._26VXCm {
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 20px;
}
