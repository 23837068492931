._2_2D3U {
  margin-top: 10px;
}
  ._2_2D3U > * {
    margin-bottom: 10px
  }
  ._2_2D3U > *:last-child {
      margin-bottom: 0;
    }
/* Gratuity */
._3Fmq54 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  height: 40px;
  overflow: hidden;
}
._2uV3jV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
}
._2E0qpn {
  margin-left: 20px;
}
._4Tm1UL {
  margin-right: 5px
}
._3iy141 {
  padding: 10px;
  cursor: pointer;
}
._2FBKlv {
  white-space: nowrap;
}
._19vQxB {
  margin-left: 5px;
}
/* Amenity */
._1pnH7d > * {
    margin-bottom: 10px
  }
._1pnH7d > *:last-child {
      margin-bottom: 0;
    }
._1cAsX5 {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  min-height: 40px;
}
._1GZ_YF {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
}
._28ikp3 {
  font-weight: 500;
  margin-right: 10px;
}
._38c7Ju {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
  font-size: 14px;
  margin-left: 15px;
}
._38c7Ju > * {
    margin-right: 10px
  }
._38c7Ju > *:last-child {
      margin-right: 0;
    }
._19h3Uz {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
}
._1k7TGn {
  font-size: 40px;
  line-height: 40px;
  margin-right: 15px;
  width: 40px;
}
._1k7TGn, ._1k7TGn svg {
    fill: #0D84EB !important;
    width: 40px;
    height: 40px;
  }
._1k7TGn g, ._1k7TGn svg g {
      fill: #0D84EB !important;
    }
._2WDuP- {
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 1px 1px 5px 5px;
}
