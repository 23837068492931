._1SI3d5 {
  margin-top: 20px;
}

._3IpnKl {
  padding-left: 0;
  color: #212121 !important;
}

._2qj8Rk {
  color: #212121;
  padding: 10px 0;
}
