._3QiuyK {
}
._25HO4b {
  overflow-y: auto;
  min-height: 400px;
  max-height: 400px;
  max-width: 755px;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
}
._1BZSRr {
  padding: 20px;
}
._1kmHn4 {
  color: #b6b6b6;
}
._38Uk_4 {
  margin-left: 12px;
}
.U6Rc8u {
  margin: auto;
  width: 90px;
}
._1LKwo_ {
  -webkit-align-self: center;
          align-self: center;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  text-align: center;
  padding: 0 20px;
  font-size: 20px;
  max-width: 100%;
}
._2K5E99 {
  border-left: none;
  border-right: none;
  padding-right: 50px;
  width: 100%;
}
._38zybQ {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
._38zybQ input {
    position: relative;
    left: -20px;
    width: 20px;
  }
._13s7LD ._38zybQ input {
      position: static;
      position: initial;
    }
._13s7LD ._38zybQ :nth-child(2) {
      margin-right: 0;
    }
._13s7LD ._1BZSRr {
    margin-bottom: 52px;
  }
._13s7LD._25HO4b {
  max-height: none;
  max-height: initial;
}
