._3mODlp {
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}
.BvlhaV {
  margin-top: 10px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
._2SUXxi {
  max-width: 920px;
  width: 100%;
  margin: 5px auto 0 auto;
}
@media all and (max-width: 959px) {
  .BvlhaV, ._2SUXxi {
    width: 100%;
    padding: 0 5px;
  }
}
@media all and (min-width: 960px) {
  .BvlhaV, ._2SUXxi {
  }
}
.MZATn1 {
  text-align: center;
  margin-top: 25px;
  font-size: 24px;
  font-weight: 400;
}
._1f6XeW {
  text-align: center;
  font-weight: 400;
}
._2k0420 {
  margin-top: 20px;
  line-height: 0;
  height: 0;
}
.v0Q2WD {
  max-width: 180px;
  max-height: 180px;
}
._1AGA16 {
  width: 100%;
  margin-bottom: 3rem;
}
._1JjBbI {
  margin-bottom: 0.5rem !important;
}
@media all and (max-width: 959px) {
  .desktop ._3ctdit {
    padding: 0 15px;
  }
}
.mobile .BvlhaV {
    width: 100%;
    padding: 10px;
  }
.mobile .v0Q2WD {
    max-width: 100px;
    max-height: 100px;
  }
._3W6PUe {
  padding: 0 !important;
  margin-top: 0;
}
._1nOU9S {
  width: 100%;
  max-width: 920px
}
._1nOU9S > div {
    -webkit-flex: 1;
            flex: 1;
    padding: 14px 0
  }
._1nOU9S > div:empty {
      display: none;
    }
/* Results with map */
._2ySur5 {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}
._8_OKY_ {
  position: relative;
}
._2DiWvV {
  width: 100%;
  position: relative;
  z-index: 10;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  padding: 0 18px 18px 18px;
}
/* Map container */
.ZDSJDT {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
._2b482e {
  height: 100vh;
  width: 100%;
  background: #eee;
}
.Gmre6l ._2b482e {
    position: fixed;
    top: 0;
  }
._3zGHKE ._2b482e {
    position: absolute;
    bottom: 0;
  }
._3fHXEa {
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  outline: none;
  padding: 10px;
  margin-right: 20px;
  border: 1px #ddd solid;
  width: 100%;
}
.JUtdW6 {
  width: 50%;
  -webkit-align-self: center;
          align-self: center;
  margin-left: -50px;
  margin-top: 20px;
}
._1ld4Zi {
  white-space: nowrap;
  margin-left: 15px;
  height: 40px;
  font-size: 16px;
  padding: 10px 30px;
  border-radius: 3px;
  min-width: 80px;
  outline: none;
  cursor: pointer;
  position: relative;
  -webkit-align-self: center;
          align-self: center;
  background: #0D84EB;
  color: #FFFFFF;
  border: none;
}
._17QF0U {
  margin-top: 60px;
}
._1ALNTJ {
  color: #0D84EB;
}
.nSOiTd {
  text-decoration: underline;
  font-weight: 600;
}
._1pKH1k {
  margin-top: 20px;
}
