._3N254I {
  color: #444;
  font-size: 14px;
}
._16V_3z {
  font-weight: bold;
  font-size: 16px;
}
.r-XTBQ {
  font-size: 14px;
  color: #b6b6b6
}
._3GY4Rx {
  margin-top: 10px;
}
._3_4juU ._3GY4Rx {
    margin: 0;
  }
