._16ItP9 {
  min-width: 100%;
  position: absolute;
  top: 100%;
  border-radius: 4px;
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.45);
  -webkit-transform: translate3d(0, 2px, 0);
          transform: translate3d(0, 2px, 0);
  z-index: 100000;
  background: #FFFFFF;
}
.uRiHb6 {
  margin: 5px;
  height: 230px;
  overflow: auto;
  padding-right: 1px
}
.uRiHb6::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
.uRiHb6::-webkit-scrollbar-thumb {
    background: #0D84EB;
    border: 0px none #ffffff;
    border-radius: 5px;
  }
.uRiHb6::-webkit-scrollbar-thumb:hover {
    background: #2F6FA8;
  }
.uRiHb6::-webkit-scrollbar-thumb:active {
    background: #2F6FA8;
  }
.uRiHb6::-webkit-scrollbar-track {
    background: transparent;
  }
.uRiHb6::-webkit-scrollbar-track:hover {
    background: transparent;
  }
.uRiHb6::-webkit-scrollbar-track:active {
    background: transparent;
  }
.uRiHb6::-webkit-scrollbar-corner {
    background: transparent;
  }
.Oj8Jnz {
  list-style-type: none;
  color: #727272;
}
._3DYel- {
  color: #727272;
  text-align: center;
  padding: 10px;
}
