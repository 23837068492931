._31ksQy {
  float: left;
  height: 50px;
}
._1mxOIU {
  display: block;
}
._1Kiv2_ {
  height: 35px;
  max-width: none;
}
