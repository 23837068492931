._2REl77 {
  color: #444;
  max-width: 920px;
  width: 100%;
  margin: 20px auto 0 auto;
}

  ._2REl77 h3 {
    font-size: 2em;
    font-weight: 400;
  }
@media all and (max-width: 959px) {
  ._2REl77 {
    width: 100%;
    padding: 0 5px;
  }
}
@media all and (min-width: 960px) {
  ._2REl77 {
    width: 920px;
  }
}
