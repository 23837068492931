/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

._2uk9hP {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

._1LK0kN {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
}

.xoNm7c {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  transition: all 0.4s cubic-bezier(.1, .7, .4, 1);
}

.zw0_1m {
  z-index: 1;
  position: relative;
  z-index: 2;
  min-width: 500px;
  transition: all 0.4s cubic-bezier(.1, .7, .4, 1)
}

@media all and (max-width: 499px) {

.zw0_1m {
    min-width: 0;
    width: 100%
}
  }

._2aLLcF {
  padding: 30px;
  background: #FFFFFF;
}

._2hJ2Du {
  position: relative;
}

._2kATcs {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
  z-index: 1;
}

._232A0E {
  position: static;
  background: transparent;
  z-index: 2;
}

._1K11AP {
}

.sUSUZT ._2aLLcF {
    padding-left: 0;
    padding-right: 0;
  }

.sUSUZT ._2hJ2Du {
    padding: 0 30px;
  }

._39IylO .zw0_1m {
    border-radius: 4px;
    overflow: hidden;
  }

._1YVfhR .zw0_1m {
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.45)
  }

._3P09SF ._2aLLcF {
    padding: 0;
    background-color: transparent;
  }
