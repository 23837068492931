/* General price details */
._2YcYzx {
  color: #212121;
}
.GzPWA2 {
  font-size: 32px;
  font-weight: 400;
  margin-right: 10px;
}
._2blWqm {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
._1h4SEa {
  display: -webkit-flex;
  display: flex;
  margin-left: 10px;
}
/* Price diff details tooltip */
._3bMaS9 {
  text-align: left;
  width: 100%;
}
._3bMaS9 > * { padding: 4px 0 4px 0; }
._3bMaS9 > *:first-child { padding: 0 0 4px 0; }
._3bMaS9 > *:last-child { padding-top: 4px 0 0 0; }
._3bMaS9 > *:first-child:last-child { padding: 0; }
.Wop08y {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  color: #b6b6b6;
}
.Wop08y > * { margin-left: 10px; }
.Wop08y > *:first-child { margin-left: 0; }
.Wop08y > *:first-child:last-child { margin-left: 0; }
._1X-cEL {
  border-top: 1px solid #727272;
  color: #212121;
}
