._2IDBXp {
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
}
._2dQ5k7 {
}
.desktop {
  min-width: 600px;
}
.mobile {
  -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 0;
  height: 100%;
  overflow: hidden;
}
