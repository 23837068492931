.UWlauX {
  margin-bottom: -10px;
}
._3O6ir6 ._1MYcG9 {
    color: #009245;
  }
.B4eOfh ._1MYcG9 {
    color: #FFA000;
  }
._8-tbQa {
  margin-top: 3px;
}
._1MYcG9 {
  font-size: 12px;
  font-weight: bold;
}
._1Nc4zn {
  margin-left: 6px;
  text-transform: lowercase;
}
._2gePjy {
  font-size: 14px;
  color: #212121;
  margin-left: 6px;
  text-transform: uppercase;
}
._3ZELUT {
  width: 100% !important;
  height: 100% !important;
}
