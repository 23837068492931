.TPaNGD {
  max-width: 400px;
  min-width: 380px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
  border: transparent;
  border-radius: 4px;
}
.XSC87r {
  min-width: 100%;
  max-width: 100%;
  padding: 30px 20px;
  box-shadow: none;
}
._16l_zU {
  margin-bottom: 30px;
  padding: 0 35px;
  font-size: 24px;
  color: #002664;
}
