/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */
/* Elements that cover the entire screen */
/* Dialogs and modals. */
/* Elements usually triggered by hovering or activating inputs by the user. */
/* Elements that support the overall layout, such as a fixed header or footer. */
/* Elements that stand out for a particular reason. */
/* Reserved for the background cover. */
/* start-of: TripPrice */
._27LsFL {
}
._2kSNKB {
  font-size: 28px;
  line-height: 26px;
  text-align: right;
}
._27c02T {
  font-size: 12px;
  color: #b83b0f;
  margin-right: 10px;
  font-weight: 500;
  background-image: linear-gradient(transparent 7px, #b83b0f 7px, #b83b0f 9px, transparent 9px);
}
._10iW5a {
  font-size: 14px;
}
.geUOCZ {
  font-size: 12px;
}
.geUOCZ ._2kSNKB {
    font-size: 26px;
  }
.geUOCZ ._10iW5a {
    font-size: 12px;
    line-height: 18px;
  }
.geUOCZ ._27c02T {
    margin-right: 0px;
    font-size: 14px;
    line-height: 18px;
  }
/* end-of: TripPrice */
.T5dCbc {
  max-width: 170px;
}
.ub1yQm {
  font-size: 14px;
  text-align: right;
  margin-top: 4px;
  margin-bottom: 6px;
  line-height: 8px;
}
/* start-of: TripProviderLogo*/
.-B4F_8 {
  position: relative;
}
._3tukXA {
  color: #FFFFFF;
  background: #4287c6;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 18px;
  font-size: 14px;
  font-weight: 500;
  z-index: 6000;
}
._3tukXA :first-child {
    margin-right: 1px;
  }
._3tukXA svg {
    width: 6px;
  }
._2XTjEz {
  width: 145px;
}
.BgbR48 {
  width: 100px;
}
.eDSvDV {
  bottom: -5px;
  right: -5px;
  background-color: #b7b7b7;
}
._3g5Pkf {
  width: 105px;
  height: 105px;
  border: 5px solid #e4e4e4;
  background-color: white;
}
._2xAOEJ {
  font-size: 12px;
  color: #b6b6b6;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}
.phK_JL {
  margin-top: 5px;
}
/* star size */
.phK_JL ._3b87Go span i {
    font-size: 18px;
  }
.irHqQm ._3g5Pkf {
    border-color: rgb(252, 191, 18) !important;
  }
.irHqQm .eDSvDV {
    background: #e2ac10;
  }
._3OQNf- {
  min-width: 65px;
  width: auto;
  height: auto;
}
._3OQNf- ._3g5Pkf {
    width: 50px;
    height: 50px;
    border-width: 2px;
  }
._3OQNf- .eDSvDV {
    bottom: -2px;
    right: -2px;
  }
._3OQNf- .phK_JL {
    margin-top: 5px;
  }
/* star size */
._3OQNf- .phK_JL ._3b87Go span i {
      font-size: 10px;
    }
._2SGfEy {
  margin: 20px 0;
  min-width: 65px;
  width: auto;
  height: auto;
}
._2SGfEy ._3g5Pkf {
    width: 55px;
    height: 55px;
    border-width: 2px;
  }
._2SGfEy .eDSvDV {
    bottom: -2px;
    right: -2px;
  }
/* end-of: TripProviderLogo*/
/* start-of: StepsSummary */
._3T78Tg {
  margin-left: -2px;
}
._1Gssw4 svg {
    fill: #444;
    width: 18px;
    height: 18px;
  }
._1Gssw4 span {
    white-space: nowrap;
  }
.RfNT8l svg {
      width: 15px;
      height: 15px;
    }
._3JOONE, ._3R3YlE {
  font-size: 16px;
}
._3JOONE {
  margin: 2px 2px 0 10px;
}
.lsNHEO {
  margin: 2px 2px 0 0;
}
._3R3YlE {
  padding: 2px 5px 0;
  margin: 0 2px 0 9px;
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 5%;
}
.vD4Y63 {
  padding: 0 5px;
  max-width: 60px;
  margin: 0;
}
/* start-of: StepsSummary */
/* GBT specific styles */
.corp-gbt ._3g5Pkf {
    border: 5px solid transparent;
  }
.corp-gbt .irHqQm ._3g5Pkf {
      border-color: #2870d2 !important;
    }
.corp-gbt ._2ulTmZ {
    background-color: #002663;
  }
.corp-gbt ._3UT1kC {
    background-color: #009bbb;
  }
.corp-gbt ._2Percl {
    background-color: #009bbb;
  }
.corp-gbt ._3WBJnd {
    background-color: #9c5fb5;
  }
.corp-gbt .phK_JL ._3b87Go span i {
    color: #2870d2;
  }
