._354XQh {
  padding-top: 40px;
  /* Important for IE */
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}
._2iBtVF {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding-right: 40px;
}
._2iBtVF > * { margin-bottom: 40px; }
._2iBtVF > *:last-child { margin-bottom: 0; }
._2iBtVF > *:last-child:first-child { margin-bottom: 0; }
@media all and (max-width: 960px) {
._2iBtVF {
     padding-right: 0;
     margin-bottom: 40px
}
  }
._37frdQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  width: 100%
}
@media all and (max-width: 960px) {
._37frdQ {
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap
}
  }
._12o1x1 {
  -webkit-flex: 0 0 250px;
          flex: 0 0 250px
}
@media all and (max-width: 960px) {
._12o1x1 {
    padding-left: 0;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto
}
  }
.trnty7 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  margin-top: 40px;
  margin-right: 290px;
  -webkit-justify-content: space-between;
          justify-content: space-between
}
@media all and (max-width: 960px) {
.trnty7 {
    margin-right: 0
}
  }
._2epj0j {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
._2epj0j > * { margin-left: 10px; }
._2epj0j > *:first-child { margin-left: 0; }
._2epj0j > *:last-child:first-child { margin-left: 0; }
._3Ist1B {
  margin-top: 20px;
  color: #f44336;
  margin-right: 290px
}
@media all and (max-width: 960px) {
._3Ist1B {
    margin-right: 0
}
  }
._3BbE4v {
  list-style: disc;
  margin-left: 20px;
}
._3gP1Ba {
  padding: 5px 0;
}
._2jDlwq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
._1qdXB2 {
  margin-bottom: 20px;
}
._3Vjk2y {
  border-radius: 50%;
  background-color: #fff;
  color: #f44336;
  font-size: 30px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  padding: 3px 5px;
  border: 1px solid #f44336;
}
._1Y1NYZ {
  padding-left: 10px;
  color: #f44336;
}
.B-FkEK {
  font-weight: 600;
}
._1V8oNM {
  margin-bottom: 10px;
}

