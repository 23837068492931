._2ggpw1 {
  min-height: 40px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

._23tWP6 {
  padding: 10px 0;
  color: #444;
  font-size: 14px;
  white-space: nowrap;
}

._2OdVdB {
  min-height: 40px;
  position: relative;
  background-color: #fff;
  border-left: 1px solid #ddd;
  font-weight: bold;
  font-size: 16px;
}

._31Q04u {
  padding: 0 20px;
  color: #46ad58;
}

.zB5LB_ {
  width: 100%;
  height: 40px;
}

._2KjjKT {
  height: 40px !important;
  border: none;
  width: 100%;
  padding: 10px;
}

._20CQ4P {
  min-width: 65px;
  height: 40px;
  font-weight: bold;
}

._1Fmgwg {
  height: 40px;
  border: none;
  width: 100px;
}
