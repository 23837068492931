/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

/* Elements that cover the entire screen */

._2VBP1u {
  z-index: 9000;
}

/* Dialogs and modals. */

.MzH6Iw {
  z-index: 8000;
}

/* Elements usually triggered by hovering or activating inputs by the user. */

._2gBdRx {
  z-index: 7000;
}

/* Elements that support the overall layout, such as a fixed header or footer. */

.WjDWOR {
  z-index: 6000;
}

/* Elements that stand out for a particular reason. */

._3gMFZ8 {
  z-index: 5000;
}

/** Reserved for the background cover. */

.DQu01J {
  z-index: 1;
}
