/* Amenites list */
._3taGs3 {
  width: 100%;
}
/* Amenites list modifiers */
._3SZemn {

}
/* amenity item */
._1i4KCQ {
  min-height: 40px;
  background-color: #f9f9f9;
  position: relative;
}
._3Z0ajY {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._1QodZI {
  margin-left: 5px;
}
.WS8dtt {
  font-size: 14px;
  color: #58b76a;
  margin-left: 10px;
}
._37lY0T {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
}
._37lY0T svg {
    width: 18px;
    height: 18px;
  }
._1q9uoD {
  margin-right: 10px;
  cursor: pointer;
  width: 10px;
  height: 10px;
  line-height: 10px;
}
._1q9uoD svg {
    width: 10px;
    height: 10px
  }
._1q9uoD svg * {
      fill: #000000;
    }
/* Loading indicator */
._2esD0j {
  -webkit-transform: scale(0.8, 0.8);
          transform: scale(0.8, 0.8);
  margin-right: 10px;
  height: 20px;
}
._2esD0j svg {
    margin: 0;
  }
._1SiyX7 {
  cursor: pointer;
}
/* Amenity item modifiers */
._3ho5m8 {
  color: #b2b2b2;
}
._3ikN58 ._1q9uoD {
    margin-right: 20px;
  }
._27m48B {
  background-color: #FFFFFF;
}
/* GBT specific styles */
.corp-gbt ._1i4KCQ {
    background-color: transparent;
  }
.corp-gbt ._1i4KCQ input[type="radio"] {
      margin: 2px;
    }
.corp-gbt .OboPSI {
    background: transparent;
  }
