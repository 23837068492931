._35aXK6 {
}

._26uBjE {
  margin: 10px;
  font-size: 12px;
  min-width: 282px;
}

._1gU4Ui {
  padding-right: 4px;
  color: #212121;
}

._36lqH5{
  margin: 0;
}

.PkQjDq {
  width: 24px;
}

._3Xsacg {
  margin-top: 20px;
}
