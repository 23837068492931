._38PabN {
  width: 100%;
  margin: 30px 0;
  padding: 20px 20px 10px;
  background-color: #f9f9f9;
  text-align: center;
}
._2pgGwZ {
  font-size: 20px;
  font-weight: normal;
  color: #4d4f53;
}
._349k7k {
  margin-top: 15px;
  text-decoration: none;
}
._349k7k ._2_nPUA {
    color: #FFFFFF;
  }
._349k7k .g6W05C {
    color: #0D84EB;
  }
._1lmI_n {
  padding: 10px;
  margin: 0 10px 15px 10px;
  min-width: 155px;
  font-size: 14px;
  border-radius: 3px;
  display: inline-block;
  transition: background 0.2s ease
}
._1lmI_n._2_nPUA {
    background: #009bbb;
    color: #FFFFFF
  }
._1lmI_n._2_nPUA:hover {
      background: rgb(0, 136, 163);
    }
._1lmI_n.g6W05C {
    border: 1px solid #009bbb;
    background: #FFFFFF;
    color: #009bbb
  }
._1lmI_n.g6W05C:hover {
      background: #e6f5f8;
    }
