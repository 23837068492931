._359jKj {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
}
.a0PEbV {
  width: 20px;
  height: 20px;
  padding: 5px;
  position: relative;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #0D84EB;
}
._31vyN2 {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 50%;
  background: #0D84EB;
}