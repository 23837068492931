._11sD_p {
  margin-bottom: 10px;
  margin-top: 10px;
}

.QwHXAI {
  cursor: default;
  color: #fff;
  background: #f24646
}

.QwHXAI:focus,
  .QwHXAI:hover,
  .QwHXAI:active {
    background: #f24646;
  }

.QwHXAI i {
    color: #fff;
    margin-right: 5px;
  }

._32TRN- {
  background: #444
}

._32TRN-:not(:last-child) {
    margin-right: 6px;
  }

._32TRN- i {
    color: #fff;
    margin-right: 5px;
  }

._3B_oRr {
  background: #444
}

._3B_oRr:not(:last-child) {
    margin-right: 6px;
  }

._32TRN-:hover {
  background: #303030;
}
