.bXZU7C {
  margin-top: 30px;
  margin-left: 20px;
}

._3D_ePt {
  font-size: 24px;
  font-weight: 300;
}

.Y6Lh1h {
  margin-top: 20px;
}
