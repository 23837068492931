.gjO4IU {
  width: 100%;
  height: 100%;
}

._2p5k_G {
  width: 100%;
  pointer-events: none;
  background: none;
  border: none;
}

._3ODrq- {
  position: absolute;
  right: 0px;
}

.gjO4IU._2ixscd ._3ODrq- {
  margin-top: -5px;
}

.gjO4IU._3CWLbi ._2p5k_G {
  padding-right: 20px;
}
