._15sHzR {
  padding: 10px;
  min-width: 80px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}
.Bp5dVC {
}
.bxtuvc {
}
._1kE3rF {
}
._1Eef_1 {
}
._1QUlwB {
}
._3OljKp {
}
._38gs-M {
  cursor: default;
}
._15sHzR.sqpNd1 {
  color: #ffffff !important;
  background-color: #b2b2b2 !important;
}
._15sHzR._2RHJn_ {
  padding: 0;
}
._15sHzR._2hyP7Z {
  padding: 2px;
}
._15sHzR._1ZKicy {
  padding: 2px 4px;
  font-size: 11px;
  min-width: 0px;
}
._15sHzR._2ooQjQ {
  border-radius: 50%;
  padding: 0;
  min-width: 0;
}
._15sHzR._22RBzU {
  border-radius: 3px;
}
._15sHzR._1M9gUh {
  font-size: 16px;
}
._15sHzR._2SQH1h {
  text-transform: uppercase;
}
._15sHzR._1Fo6qm {
  text-transform: capitalize;
}
._15sHzR._2wCbyw {
  background: transparent !important;
}
._15sHzR._1s4U3W {
  min-width: 0;
}
._15sHzR.G_Nmag {
  font-weight: bolder;
}
._15sHzR._1qtWTA ._3gdWc2 {
    margin-left: 10px;
  }
._15sHzR._2bb2X8 {
  border-radius: 500px;
  text-transform: none;
  padding: 11px 30px;
  background-color: rgba(0,0,0,0.8);
  height: 42px;
}
._15sHzR._2bb2X8 svg, ._15sHzR._2bb2X8 i {
    margin-left: 10px;
  }
