._3qTQTy {
  font-size: 14px;
  min-height: 40px;
  white-space: nowrap;
}

._1nwDyi {
  vertical-align: middle;
  margin-right: 5px;
  height: 18px;
}
