._3lkU39 {
  white-space: nowrap;
}

.qW2AQO {
  padding: 11px 15px
}

.qW2AQO:nth-child(2) {
    border-left: 1px solid #e6e6e6;
  }

._2QeSR8 {
  width: 35px;
  height: 35px;
  fill: green;
  margin-right: 5px;
}

.BNm1sn {
  font-size: 14px;
  color: green;
  white-space: nowrap;
  line-height: 1em;
  margin-top: 7px;
}

._1-OZzy {
  white-space: normal;
  font-size: 11px;
  line-height: 1em;
  color: #aaa;
}

._3C9BEN ._2QeSR8 {
    fill: orange;
  }

._3C9BEN .BNm1sn {
    color: orange;
  }
