/* System variables */
/* Header: Light mode */
.mz-nav-container {
  background: #ffffff;
  box-shadow: 0 0 3px #dddddd;
  border-bottom: 1px solid #dddddd;
}
.mz-nav__button {
  height: 50px
}
.mz-nav__button:hover {
    background-color: rgba(13, 132, 235, 0.1);
  }
.mz-nav__button:active {
    background-color: rgba(13, 132, 235, 0.3);
  }
.mz-nav__button:active .mz-nav__link {
      color: #444;
    }
.mz-nav__button--inactive {
  opacity: 0.3;
  background-color: #ffffff !important;
}
.mz-nav__button--active {
  border-bottom: 3px solid #0D84EB;
  background-color: #ffffff !important;
  padding-top: 3px;
}
.mz-nav__button--active .mz-nav__link {
    color: #444 !important;
  }
.mz-nav__dropdownButton:after {
  border-left: 1.5px solid #444;
  border-top: 1.5px solid #444;
}
.mz-nav__dropdownItem:hover {
    background-color: rgb(247, 247, 247);
  }
.mz-nav__dropdownItem:active {
    background-color: rgb(235, 235, 235);
  }
/* Header: Dark mode */
.mz-dark-mode .mz-nav-container {
    background: #0D84EB;
    border-bottom: none;
  }
.mz-dark-mode .mz-nav__link {
    color: #ffffff;
  }
.mz-dark-mode .mz-nav__button:hover {
      background-color: #0D84EB;
    }
.mz-dark-mode .mz-nav__button:active {
      background-color: #0D84EB;
    }
.mz-dark-mode .mz-nav__button:active .mz-nav__link {
        color: #ffffff;
      }
.mz-dark-mode .mz-nav__button--inactive {
    opacity: 1;
    background-color: #0D84EB !important;
    color: rgba(13, 132, 235, 0.7);
  }
.mz-dark-mode .mz-nav__button--active {
    border-bottom: 3px solid #0D84EB;
    background-color: #0D84EB !important;
    padding-top: 3px;
  }
.mz-dark-mode .mz-nav__button--active .mz-nav__link {
      color: #ffffff !important;
    }
.mz-dark-mode .mz-nav__dropdownButton:after {
    border-left: 1.5px solid #ffffff;
    border-top: 1.5px solid #ffffff;
  }
.mz-nav {
  min-height: 50px;
}
.mz-nav__logoImg {
  height: 35px;
}
.mz-nav__logo {
  height: 50px;
}
.mz-nav__link {
  color: #0D84EB;
}
/* Fix height of buttons in header in mobile mode */
@media all and (max-width: 600px) {
  .mz-nav__button {
    height: 50px;
  }
  .mz-nav__hamburger {
    height: 50px;
  }
}
/* Form: Cover */
.mz-cover {
  background-image: url('https://static.mozio.com/assets/hero.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover
}
.mz-cover:before {
    background: rgba(51,51,51,0.3);
  }
.mz-home-header {
  color: #FFFFFF;
}
/* Form: Commons */
.mz-formfield {
  background: #0D84EB;
  color: #ffffff;
}
.mz-formfield__input {
  color: #888888;
  background: #ffffff;
}
.mz-formfield__icon {
  color: #888888;
}
/* Form: Tabs */
.mz-tabs__label {
}
.mz-tabs__label-icon {
  color: #444;
}
.mz-tabs--primary .mz-tabs__bar, .mz-tabs--default .mz-tabs__bar {
    background: #ffffff;
  }
.mz-tabs--primary .mz-tabs__label, .mz-tabs--default .mz-tabs__label {
    color: rgb(105, 181, 247)
  }
.mz-tabs--primary .mz-tabs__label:active,
    .mz-tabs--primary .mz-tabs__label:visited,
    .mz-tabs--default .mz-tabs__label:active,
    .mz-tabs--default .mz-tabs__label:visited {
      color: rgb(105, 181, 247);
    }
.mz-tabs--primary .mz-tabs__label:after, .mz-tabs--default .mz-tabs__label:after {
      opacity: 0;
      background: #0D84EB;
    }
.mz-tabs--primary .mz-tabs__label:hover,
    .mz-tabs--primary .mz-tabs__label.active,
    .mz-tabs--default .mz-tabs__label:hover,
    .mz-tabs--default .mz-tabs__label.active {
      color: #0D84EB
    }
.mz-tabs--primary .mz-tabs__label:hover:after, .mz-tabs--primary .mz-tabs__label.active:after, .mz-tabs--default .mz-tabs__label:hover:after, .mz-tabs--default .mz-tabs__label.active:after {
        opacity: 1;
      }
.mz-tabs--primary .mz-tabs__label:hover .mz-tabs__label-icon, .mz-tabs--primary .mz-tabs__label.active .mz-tabs__label-icon, .mz-tabs--default .mz-tabs__label:hover .mz-tabs__label-icon, .mz-tabs--default .mz-tabs__label.active .mz-tabs__label-icon {
        color: #0D84EB;
      }
.mz-tabs--simple .mz-tabs__bar {
    background: #ffffff;
  }
.mz-tabs--simple .mz-tabs__label {
    background-color: rgb(237, 237, 237)
  }
.mz-tabs--simple .mz-tabs__label:active,
    .mz-tabs--simple .mz-tabs__label:visited {
      color: rgb(105, 181, 247);
    }
.mz-tabs--simple .mz-tabs__label:hover,
    .mz-tabs--simple .mz-tabs__label.active {
      color: #ffffff;
      background-color: #0D84EB;
    }
.mz-tabs--simple .mz-tabs__label:hover .mz-tabs__label-icon, .mz-tabs--simple .mz-tabs__label.active .mz-tabs__label-icon {
        color: #0D84EB;
      }
.mz-icon {
}
.mz-primary-icon.mz-icon,
.mz-primary-icon.mz-icon.mz-interactive-icon:hover {
  color: #0D84EB;
  fill: #0D84EB;
  border-color: #0D84EB;
}
.mz-interactive-icon {
}
.mz-default-title {
  color: #0D84EB;
}
.mz-default-dark-font {
  color: #444;
}
.mz-default-gray-font {
  color: #888888;
}
.mz-default-dark-icon {
  fill: #444;
}
.mz-font-icon {
  color: #888888;
}
.mz-font-icon.mz-interactive-icon:hover {
  color: rgb(84, 84, 84);
}
.mz-stamp-icon {
  color: #888888;
  border: 2px solid #888888;
}
.mz-stamp-icon.mz-interactive-icon:hover {
  color: rgb(84, 84, 84);
  border-color: rgb(84, 84, 84);
}
.mz-svg-icon {
  fill: #888888;
}
.mz-svg-icon.mz-interactive-icon:hover {
  fill: rgb(84, 84, 84);
}
.mz-icon--dark {
}
.mz-icon--dark.mz-font-icon {
  color: #444;
}
.mz-icon--dark.mz-font-icon.mz-interactive-icon:hover {
  color: rgb(18, 18, 18);
}
.mz-icon--dark.mz-stamp-icon {
  color: #444;
  border-color: #444;
}
.mz-icon--dark.mz-stamp-icon.mz-interactive-icon:hover {
  color: rgb(18, 18, 18);
  border-color: rgb(18, 18, 18);
}
.mz-icon--dark.mz-svg-icon,
  .mz-icon--dark.mz-svg-icon path,
  .mz-icon--dark.mz-svg-icon circle,
  .mz-icon--dark.mz-svg-icon canvas {
    fill: #444;
  }
.mz-icon--dark.mz-svg-icon.mz-interactive-icon:hover,
  .mz-icon--dark.mz-svg-icon.mz-interactive-icon:hover path,
  .mz-icon--dark.mz-svg-icon.mz-interactive-icon:hover circle,
  .mz-icon--dark.mz-svg-icon.mz-interactive-icon:hover canvas {
    fill: rgb(18, 18, 18);
  }
.mz-icon--primary {
}
.mz-icon--primary.mz-font-icon {
  color: #0D84EB;
}
.mz-icon--primary.mz-font-icon.mz-interactive-icon:hover {
  color: rgb(7, 78, 141);
}
.mz-icon--primary.mz-stamp-icon {
  color: #0D84EB;
  border-color: #0D84EB;
}
.mz-icon--primary.mz-stamp-icon.mz-interactive-icon:hover {
  color: rgb(7, 78, 141);
  border-color: rgb(7, 78, 141);
}
.mz-icon--primary.mz-svg-icon * {
  fill: #0D84EB;
}
.mz-icon--primary.mz-svg-icon.mz-interactive-icon:hover * {
  fill: rgb(7, 78, 141);
}
.mz-icon--secondary {
}
.mz-icon--secondary.mz-font-icon {
  color: #FCD012;
}
.mz-icon--secondary.mz-font-icon.mz-interactive-icon:hover {
  color: rgb(167, 136, 2);
}
.mz-icon--secondary.mz-stamp-icon {
  color: #FCD012;
  border-color: #FCD012;
}
.mz-icon--secondary.mz-stamp-icon.mz-interactive-icon:hover {
  color: rgb(167, 136, 2);
  border-color: rgb(167, 136, 2);
}
.mz-icon--secondary.mz-svg-icon,
  .mz-icon--secondary.mz-svg-icon path,
  .mz-icon--secondary.mz-svg-icon circle,
  .mz-icon--secondary.mz-svg-icon canvas {
    fill: #FCD012;
  }
.mz-icon--secondary.mz-svg-icon.mz-interactive-icon:hover,
  .mz-icon--secondary.mz-svg-icon.mz-interactive-icon:hover path,
  .mz-icon--secondary.mz-svg-icon.mz-interactive-icon:hover circle,
  .mz-icon--secondary.mz-svg-icon.mz-interactive-icon:hover canvas {
    fill: rgb(167, 136, 2);
  }
.mz-icon--error {
}
.mz-icon--error.mz-font-icon {
  color: #c7254e;
}
.mz-icon--error.mz-font-icon.mz-interactive-icon:hover {
  color: rgb(112, 21, 43);
}
.mz-icon--error.mz-stamp-icon {
  color: #c7254e;
  border-color: #c7254e;
}
.mz-icon--error.mz-stamp-icon.mz-interactive-icon:hover {
  color: rgb(112, 21, 43);
  border-color: rgb(112, 21, 43);
}
.mz-icon--error.mz-svg-icon * {
  fill: #c7254e;
}
.mz-icon--error.mz-svg-icon.mz-interactive-icon:hover * {
  fill: rgb(112, 21, 43);
}
/* Trip card */
.mz-trip--active.mz-trip:before {
  border-left: 6px solid #0D84EB;
}
/* Form: Tab picker */
.mz-tabpicker__option-label {
  color: #888888;
  background: #ffffff
}
.mz-tabpicker__option-label:hover,
  .mz-tabpicker__option-label:focus {
    color: #888888
  }
.mz-tabpicker__option-label:hover:after, .mz-tabpicker__option-label:focus:after {
      background: #0D84EB;
    }
.mz-tabpicker__option-label:hover .mz-tabpicker__option-icon, .mz-tabpicker__option-label:focus .mz-tabpicker__option-icon {
      color: #888888;
    }
.mz-tabpicker__option-input:checked ~ .mz-tabpicker__option-label {
    color: #888888
  }
.mz-tabpicker__option-input:checked ~ .mz-tabpicker__option-label:after {
      background: #0D84EB;
    }
.mz-tabpicker__option-input:checked ~ .mz-tabpicker__option-label .mz-tabpicker__option-icon {
      color: #0D84EB;
    }
.mz-tabpicker__option-icon {
}
/* Form: Buttons */
.mz-button {
}
.mz-button-default {
  background: #0D84EB;
  color: #ffffff;
  border: none;
}
.mz-button-default .mz-font-icon {
    color: #ffffff;
  }
.mz-button-default .mz-svg-icon {
    fill: #ffffff;
  }
.mz-button-default:after {
    color: #ffffff;
  }
.mz-button-default:focus,
  .mz-button-default:hover {
    background: rgb(11, 119, 213);
  }
.mz-button-default:active {
    background: rgb(10, 105, 189);
  }
.mz-button-default.mz-button-disabled,
  .mz-button-default:disabled {
    opacity: 0.3;
  }
.mz-button-primary {
  background: #FCD012;
  color: #ffffff;
  border: none;
}
.mz-button-primary .mz-font-icon {
    color: #ffffff;
  }
.mz-button-primary .mz-svg-icon {
    fill: #ffffff;
  }
.mz-button-primary:after {
    color: #ffffff;
  }
.mz-button-primary:focus,
  .mz-button-primary:hover {
    background: rgb(242, 198, 2);
  }
.mz-button-primary:active {
    background: rgb(217, 178, 2);
  }
.mz-button-primary.mz-button-disabled,
  .mz-button-primary:disabled {
    opacity: 0.3;
  }
.mz-button-bordered {
  background: #ffffff;
  color: #0D84EB;
  fill: #0D84EB;
  border: 1px solid #0D84EB;
  border-radius: 1px;
}
.mz-button-bordered .mz-font-icon {
    color: #0D84EB;
  }
.mz-button-bordered .mz-svg-icon {
    fill: #0D84EB;
  }
.mz-button-bordered:hover,
  .mz-button-bordered:focus {
    color: rgb(10, 105, 189);
    border: 1px solid rgb(10, 105, 189);
  }
.mz-button-bordered:after {
    color: #0D84EB;
  }
.mz-button-outlined {
  background: transparent;
  border: 2px solid !important;
  border-color: #0D84EB !important;
  color: #0D84EB;
}
.mz-button-outlined .mz-font-icon {
    color: #0D84EB;
  }
.mz-button-outlined .mz-svg-icon {
    fill: #0D84EB;
  }
.mz-button-outlined:hover {
    background: rgba(255, 255, 255, 0.2);
  }
.mz-button-outlined-secondary {
  border-color: rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}
.mz-button-outlined-secondary .mz-font-icon {
    color: rgba(255, 255, 255, 0.8);
  }
.mz-button-outlined-secondary .mz-svg-icon {
    fill: rgba(255, 255, 255, 0.8);
  }
.mz-button-outlined-secondary:hover {
    color: rgba(255, 255, 255, 1);
  }
.mz-button-clear {
  color: #0D84EB;
  background: #ffffff;
  border: none;
}
.mz-button-clear .mz-icon {
    color: #0D84EB;
  }
.mz-button-clear:hover,
  .mz-button-clear:focus {
    color: rgb(9, 92, 165);
  }
.mz-button-clear.mz-button-disabled,
  .mz-button-clear:disabled {
    color: rgb(186, 186, 186)
  }
.mz-button-clear.mz-button-disabled:hover,
    .mz-button-clear.mz-button-disabled:focus,
    .mz-button-clear:disabled:hover,
    .mz-button-clear:disabled:focus {
      background: #ffffff;
    }
.mz-button-clear-alt {
  color: rgb(84, 84, 84);
  border: none
}
.mz-button-clear-alt:hover,
  .mz-button-clear-alt:focus {
    background: rgba(13, 132, 235, 0.2);
  }
.mz-button-clear-alt.mz-button-disabled,
  .mz-button-clear-alt:disabled {
    color: rgb(186, 186, 186)
  }
.mz-button-clear-alt.mz-button-disabled:hover,
    .mz-button-clear-alt.mz-button-disabled:focus,
    .mz-button-clear-alt:disabled:hover,
    .mz-button-clear-alt:disabled:focus {
      background: #ffffff;
    }
/* Links */
.mz-link {
  color: #0D84EB;
  background: #ffffff
}
.mz-link:hover,
  .mz-link:focus {
    color: rgb(9, 92, 165);
  }
.mz-caption {
  color: #0D84EB !important;
}
/* Form: Switch */
.mz-switch {
  color: #ffffff
}
.mz-switch:before {
    background-color: #ffffff;
  }
.mz-switch:after {
    background-color: #0D84EB;
  }
.mz-switch--on:before {
    background-color: rgb(9, 92, 165);
  }
.mz-switch--on:after {
    background-color: #0D84EB;
  }
/* Form: CounterInput */
.mz-counterinput {
  background: #ffffff;
}
.mz-counterinput .mz-font-icon {
    color: #0D84EB;
  }
.mz-counterinput:hover {
    background: #0D84EB;
  }
.mz-counterinput:hover .mz-font-icon {
      color: #ffffff;
    }
/* Guarantee section */
.mz-guarantee {
  background: #ffffff;
}
.mz-guarantee__label {
  color: #0D84EB;
}
.mz-guarantee__icon {
  color: #0D84EB;
}
/* Steps section */
.mz-steps {
  background: #eeeeee;
}
.mz-steps__label {
  color: #0D84EB;
}
.mz-steps__icon {
  background: #0D84EB;
}
/* Testimonials section */
.mz-testimonials {
  background: #eeeeee;
}
.mz-testimonials__title {
  color: #000000;
}
.mz-testimonials__quote {
  color: #888888;
  background: #ffffff
}
.mz-testimonials__quote:after {
    border-color: #ffffff transparent transparent;
  }
.mz-testimonials__name {
  color: #888888;
}
.mz-default-gray-font {
  color: #888888;
}
/* Validation messages */
.mz-validation--required {
  color: #FCD012;
  background-color: rgb(255, 253, 245);
}
.mz-validation--invalid {
  color: #c7254e;
  background-color: rgb(248, 216, 224);
}
.mz-validation--clear-background {
  background-color: #ffffff;
}
/* Notification bar */
.mz-notification--success {
  color: rgb(255, 255, 255);
  background-color: #8bc34a;
}
.mz-notification--success .mz-font-icon,
  .mz-notification--success .mz-font-icon.mz-interactive-icon:hover {
    color: rgb(255, 255, 255);
  }
.mz-notification--success .mz-svg-icon,
  .mz-notification--success .mz-svg-icon.mz-interactive-icon:hover {
    fill: rgb(255, 255, 255);
  }
.mz-notification--error {
  color: rgb(255, 255, 255);
  background-color: #c7254e;
}
.mz-notification--error .mz-font-icon,
  .mz-notification--error .mz-font-icon.mz-interactive-icon:hover {
    color: rgb(255, 255, 255);
  }
.mz-notification--error .mz-svg-icon,
  .mz-notification--error .mz-svg-icon.mz-interactive-icon:hover {
    fill: rgb(255, 255, 255);
  }
.mz-notification--warning {
  color: rgb(255, 255, 255);
  background-color: #FCD012;
}
.mz-notification--warning .mz-font-icon,
  .mz-notification--warning .mz-font-icon.mz-interactive-icon:hover {
    color: rgb(255, 255, 255);
  }
.mz-notification--warning .mz-svg-icon,
  .mz-notification--warning .mz-svg-icon.mz-interactive-icon:hover {
    fill: rgb(255, 255, 255);
  }
.mz-notification--info {
  color: rgb(255, 255, 255);
  background-color: #0D84EB;
}
.mz-notification--info .mz-font-icon,
  .mz-notification--info .mz-font-icon.mz-interactive-icon:hover {
    color: rgb(255, 255, 255);
  }
.mz-notification--info .mz-svg-icon,
  .mz-notification--info .mz-svg-icon.mz-interactive-icon:hover {
    color: rgb(255, 255, 255);
  }
/* Footer */
.mz-footer {
  background: #ffffff;
}
.mz-footer__link {
  color: #0D84EB
}
.mz-footer__link:hover,
  .mz-footer__link:focus {
    color: rgb(9, 92, 165);
  }
.mz-footer__phone {
  color: rgb(84, 84, 84)
}
.mz-footer__phone:hover,
  .mz-footer__phone:focus {
    color: #000000;
  }
.mz-footer__bar {
  color: rgb(84, 84, 84);
}
.mz-footer__bar-link {
  color: rgb(84, 84, 84)
}
.mz-footer__bar-link:hover,
  .mz-footer__bar-link:focus {
    color: #000000;
  }
/* About class dropdown */
.mz-aboutclass-dd__item {
}
.mz-aboutclass-dd__icon {
  border: 2px solid rgb(10, 105, 189);
  color: rgb(10, 105, 189);
}
.mz-aboutclass-dd__features {
  background: rgb(10, 105, 189);
  color: #ffffff;
}
.mz-aboutclass-dd__list .mz-aboutclass-dd__item:hover {
    background-color: rgb(216, 236, 253);
  }
/* Mobile autocomplete */
.mz-mob-autocomplete {
  background: #ffffff;
}
.mz-mob-autocomplete__input {
  border-bottom: 1px solid #dddddd !important;
}
.mz-mob-autocomplete__label {
  color: #000000;
}
/* Mobile sorter item */
.mz-mob-sorter {
  color: #000;
}
.mz-mob-sorter__icon {
}
.mz-mob-sorter--active {
  color: #0D84EB !important;
}
.mz-mob-sorter--active .mz-mob-sorter__icon {
    fill: #0D84EB;
  }
/* Mobile screen styles */
.mz-mob-screen__nav {
  border-bottom: 1px solid #dddddd;
}
/* Loading icon */
.mz-loading-icon {
}
.mz-loading-icon--white .mz-loading-icon__path {
    stroke: #ffffff;
  }
.mz-loading-icon__path {
  stroke: rgb(10, 105, 189);
}
/* Sidebar */
.mz-sidebar {
}
.mz-sidebar__item {
  color: #0D84EB
}
.mz-sidebar__item:hover {
    color: #ffffff;
    background: rgb(7, 78, 141);
  }
.mz-sidebar__item--important,
.mz-sidebar__item--important .mz-icon {
  color: #ffffff;
  background: #FCD012;
}
.mz-sidebar__item--active,
.mz-sidebar__item--active .mz-icon {
  color: #ffffff;
  background: #0D84EB;
}
/* Dialog with price breackdown */
.mz-dialog__title,
.mz-header-bar__title {
  background-color: #0D84EB;
  color: #ffffff;
}
.mz-dialog__subtitle {
  color: rgb(10, 105, 189);
}
.mz-dialog__header {
  color: #0D84EB;
}
.mz-dialog__close:hover {
    color: #0D84EB;
    background: #0D84EB;
  }
.mz-table__row-selected {
  background-color: #0D84EB;
  color: #ffffff;
}
/* Header notification */
.mz-header-bar__title {
  background-color: #0D84EB;
  color: #ffffff;
}
.mz-header-bar__status {
  background-color: #58b76a;
  color: #ffffff;
}
.mz-dark-mode .mz-header-bar__title {
    background-color: rgb(9, 92, 165);
  }
/* SRP Filter menu button */
.mz-filtermenu {
  background-color: #0D84EB;
}
.mz-filtermenu_button {
  background-color: #0D84EB;
}
.mz-filtermenu_button:hover,
  .mz-filtermenu_button--hover {
    background-color: rgb(11, 119, 213);
    border-color: rgb(10, 111, 199) !important;
  }
.mz-filtermenu_button--active,
  .mz-filtermenu_button--active_state:active {
    background-color: rgb(81, 169, 246) !important;
    border-color: rgb(56, 157, 245) !important;
  }
.mz-filtermenu_button--mobile {
  background-color: #ffffff !important;
  border: none !important;
  color: #0D84EB !important;
}
.mz-filtermenu_button--mobile .mz-icon {
    fill: #0D84EB;
  }
.mz-filtermenu_button--mobile.mz-filtermenu_button--hover {
    background-color: #0D84EB !important;
    color: #ffffff !important;
  }
.mz-filtermenu_button--mobile.mz-filtermenu_button--hover .mz-icon {
      fill: #ffffff;
    }
/* Desktop Calendar */
.mz-calendar {
}
.mz-calendar__tile:hover,
  .mz-calendar__tile:focus {
    background: rgba(13, 132, 235, 0.2);
  }
.mz-calendar__tile--active {
  background: #0D84EB !important;
  color: #ffffff !important
}
.mz-calendar__tile--active:hover,
  .mz-calendar__tile--active:focus {
    color: #ffffff !important;
    background: #0D84EB !important;
  }
.mz-calendar__tile--target {
  background: #FCD012 !important;
  color: #ffffff !important
}
.mz-calendar__tile--target:hover,
  .mz-calendar__tile--target:focus {
    color: #ffffff !important;
    background: #FCD012 !important;
  }
/* Mobile datetime picker */
.mz-mob-datetime {
  background: #ffffff;
}
.mz-mob-datetime__date {
  color: #000000;
}
.mz-mob-datetime__day--today {
  border: 1px solid #FCD012;
}
.mz-mob-datetime__day--past {
  color: #dddddd;
}
.mz-mob-datetime__day--active {
  background: #0D84EB;
  color: #ffffff;
}
.mz-mob-datetime__time {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  color: #000000;
}
.mz-mob-datetime__time-select {
  color: #000000;
  background: rgb(247, 247, 247);
}
.mz-mob-datetime__time-arrow:after {
    border-top: 1.5px solid #0D84EB;
    border-left: 1.5px solid #0D84EB;
  }
/* Mobile date field */
.mz-mob-datefield {
}
.mz-mob-datefield__icon {
}
.mz-mob-datefield--return .mz-mob-datefield__icon {
    fill: #0D84EB;
  }
/* Mobile list */
.mz-mob-list {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}
.mz-mob-list__item {
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  color: #444
}
.mz-mob-list__item:last-child {
    border-bottom: none;
  }
.mz-mob-list__header {
  color: #444;
}
.mz-icon-container {
  fill: #444;
}
/* Standard Input */
.mz-standard-input {
}
.mz-standard-input__input {
  color: #444;
}
.mz-standard-input__prefix {
  color: #444;
}
.mz-standard-input__input:disabled {
  color: #dddddd;
}
.mz-standard-input__input::-webkit-input-placeholder {
  color: #888888;
}
.mz-standard-input__input:-ms-input-placeholder {
  color: #888888;
}
.mz-standard-input__input::placeholder {
  color: #888888;
}
/* Compact Input */
.mz-compact-input {
  border-color: transparent;
}
.mz-compact-input.mz-compact-input--alpha .mz-compact-input__label {
  color: #ffffff;
}
.mz-compact-input.mz-compact-input--success .mz-standard-input__input {
    color: rgb(113, 164, 55);
  }
.mz-compact-input.mz-compact-input--error .mz-standard-input__input {
    color: #c7254e;
  }
.mz-compact-input--underlined {
  border-color: #c3c3c3
}
.mz-compact-input--underlined.mz-compact-input--active {
    border-color: #FCD012;
  }
.mz-compact-input--white-underlined {
  border-color: #ffffff
}
.mz-compact-input--white-underlined.mz-compact-input--active {
    border-color: #FCD012;
  }
.mz-compact-input__label {
  color: rgba(136, 136, 136, 0.5);
}
.mz-compact-input__mob-field {
  color: #888888;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  border-left: 1px solid #dddddd
}
.mz-compact-input__mob-field:first-child {
    border-left: none;
  }
.mz-compact-input--slim .mz-compact-input__label {
    color: #0D84EB;
  }
/* Transition input */
.mz-transinput--filled .mz-transinput__placeholder {
    color: #0D84EB;
    background: #ffffff;
  }
.mz-transinput--filled .mz-transinput__label {
    color: #0D84EB;
  }
.mz-transinput--required .mz-transinput__bar:after,
  .mz-transinput--required .mz-transinput__bar:before {
    background: #FCD012;
  }
.mz-transinput--invalid .mz-transinput__bar:after,
  .mz-transinput--invalid .mz-transinput__bar:before {
    background: #c7254e;
  }
.mz-transinput__placeholder {
  color: #888888;
}
.mz-transinput__input {
}
.mz-transinput__input:focus ~ .mz-transinput__placeholder {
  color: #0D84EB;
}
.mz-transinput__label {
  color: #444;
}
.mz-transinput__bar:before,
  .mz-transinput__bar:after {
    background: #0D84EB;
  }
/* Checkbox */
.mz-checkbox {
  color: #444;
}
.mz-checkbox--interactive {
  transition: background 0.2s ease-in-out
}
.mz-checkbox--interactive:hover,
  .mz-checkbox--interactive:focus {
    background: rgba(0, 0, 0, 0.1);
  }
.mz-checkbox--active .mz-checkbox__icon {
    color: #0D84EB;
  }
.mz-checkbox__icon {
  color: rgba(0, 0, 0, 0.2);
  fill: rgba(0, 0, 0, 0.2);
}
/* SR Category */
.mz-sr-category {
}
.mz-sr-category__name {
  color: #0D84EB;
}
.mz-sr-category__icon {
  fill: #0D84EB;
}
.mz-sr-category__more {
  background: rgb(247, 247, 247);
}
/* Rounded header */
.mz-rounded-header {
  border: 1px solid rgba(13, 132, 235, 0.5);
  background-color: rgba(13, 132, 235, 0.2);
}
.mz-rounded-header__icon {
  background-color: #0D84EB;
  color: #ffffff;
}
.mz-rounded-header .mz-font-icon {
  color: #ffffff;
}
.mz-rounded-header .mz-svg-icon {
  fill: #ffffff;
}
.mz-rounded-header__title {
  color: #0D84EB;
}
/* Interactive Info Block */
.mz-interactive-info-block {
  color: #444;
}
.mz-interactive-info-block--green {
  background-color: #58b76a;
  border: 1px solid #378c43;
  color: #ffffff;
}
.mz-interactive-info-block--green .mz-interactive-info-block__close {
    color: rgb(58, 136, 72)
  }
.mz-interactive-info-block--green .mz-interactive-info-block__close:focus,
    .mz-interactive-info-block--green .mz-interactive-info-block__close:hover {
      color: rgb(54, 125, 67);
    }
.mz-interactive-info-block--green .mz-interactive-info-block__close:active {
      color: rgb(38, 89, 48);
    }
.mz-interactive-info-block--green .mz-interactive-info-block__close:disabled {
      color: rgb(101, 189, 118);
    }
.mz-interactive-info-block--green .mz-interactive-info-block__button {
    background-color: rgb(58, 136, 72)
  }
.mz-interactive-info-block--green .mz-interactive-info-block__button:focus,
    .mz-interactive-info-block--green .mz-interactive-info-block__button:hover {
      background-color: rgb(54, 125, 67);
    }
.mz-interactive-info-block--green .mz-interactive-info-block__button:active {
      background-color: rgb(50, 118, 63);
    }
.mz-interactive-info-block--green .mz-interactive-info-block__button:disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgb(101, 189, 118);
    }
.mz-interactive-info-block--green .mz-interactive-info-block__icon {
    background-color: #ffffff;
    color: rgb(67, 157, 84);
  }
.mz-interactive-info-block--green .mz-interactive-info-block__icon.mz-svg-icon,
    .mz-interactive-info-block--green .mz-interactive-info-block__icon .mz-svg-icon {
      fill: rgb(67, 157, 84);
    }
.mz-interactive-info-block--green .mz-interactive-info-block__title {
    color: #ffffff;
  }
.mz-interactive-info-block--green .mz-interactive-info-block__text {
    color: #ffffff;
  }
.mz-interactive-info-block--red {
  background-color: #fff3f3;
  border: 1px solid #f75757;
}
.mz-interactive-info-block--red .mz-interactive-info-block__close {
    color: #c45151
  }
.mz-interactive-info-block--red .mz-interactive-info-block__close:focus,
    .mz-interactive-info-block--red .mz-interactive-info-block__close:hover {
      color: rgb(190, 65, 65);
    }
.mz-interactive-info-block--red .mz-interactive-info-block__close:active {
      color: rgb(182, 62, 62);
    }
.mz-interactive-info-block--red .mz-interactive-info-block__close:disabled {
      color: rgb(221, 156, 156);
    }
.mz-interactive-info-block--red .mz-interactive-info-block__button {
    background-color: #c45151
  }
.mz-interactive-info-block--red .mz-interactive-info-block__button:focus,
    .mz-interactive-info-block--red .mz-interactive-info-block__button:hover {
      background-color: rgb(190, 65, 65);
    }
.mz-interactive-info-block--red .mz-interactive-info-block__button:active {
      background-color: rgb(182, 62, 62);
    }
.mz-interactive-info-block--red .mz-interactive-info-block__button:disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgb(221, 156, 156);
    }
.mz-interactive-info-block--red .mz-interactive-info-block__icon {
    background-color: #ffffff;
    color: #f75757;
  }
.mz-interactive-info-block--red .mz-interactive-info-block__icon.mz-svg-icon,
    .mz-interactive-info-block--red .mz-interactive-info-block__icon .mz-svg-icon {
      fill: #f75757;
    }
.mz-interactive-info-block--red .mz-interactive-info-block__title {
    color: #c45151;
  }
.mz-interactive-info-block--red .mz-interactive-info-block__text {
    color: #000000;
  }
.mz-interactive-info-block--red .mz-interactive-info-block__icon--rounded {
    border-color: #f75757;
  }
.mz-interactive-info-block--blue {
  background-color: rgba(13, 132, 235, 0.2);
  border: 1px solid rgba(13, 132, 235, 0.5);
}
.mz-interactive-info-block--blue .mz-interactive-info-block__close {
    color: #0D84EB
  }
.mz-interactive-info-block--blue .mz-interactive-info-block__close:focus,
    .mz-interactive-info-block--blue .mz-interactive-info-block__close:hover {
      color: rgb(11, 122, 218);
    }
.mz-interactive-info-block--blue .mz-interactive-info-block__close:active {
      color: rgb(11, 116, 208);
    }
.mz-interactive-info-block--blue .mz-interactive-info-block__close:disabled {
      color: rgb(105, 181, 247);
    }
.mz-interactive-info-block--blue .mz-interactive-info-block__button {
    background-color: #0D84EB
  }
.mz-interactive-info-block--blue .mz-interactive-info-block__button:focus,
    .mz-interactive-info-block--blue .mz-interactive-info-block__button:hover {
      background-color: rgb(11, 122, 218);
    }
.mz-interactive-info-block--blue .mz-interactive-info-block__button:active {
      background-color: rgb(11, 116, 208);
    }
.mz-interactive-info-block--blue .mz-interactive-info-block__button:disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgb(105, 181, 247);
    }
.mz-interactive-info-block--blue .mz-interactive-info-block__icon {
    background-color: #ffffff;
    color: #0D84EB;
  }
.mz-interactive-info-block--blue .mz-interactive-info-block__icon.mz-svg-icon,
    .mz-interactive-info-block--blue .mz-interactive-info-block__icon .mz-svg-icon {
      fill: #0D84EB;
    }
.mz-interactive-info-block--blue .mz-interactive-info-block__title {
    color: #0D84EB;
  }
.mz-interactive-info-block--blue .mz-interactive-info-block__text {
    color: #444;
  }
.mz-interactive-info-block--white {
  background-color: #ffffff;
  border: 1px solid rgb(222, 222, 222);
}
.mz-interactive-info-block--white .mz-interactive-info-block__close {
    color: rgb(222, 222, 222)
  }
.mz-interactive-info-block--white .mz-interactive-info-block__close:focus,
    .mz-interactive-info-block--white .mz-interactive-info-block__close:hover {
      color: rgb(209, 209, 209);
    }
.mz-interactive-info-block--white .mz-interactive-info-block__close:active {
      color: rgb(196, 196, 196);
    }
.mz-interactive-info-block--white .mz-interactive-info-block__close:disabled {
      color: rgb(255, 255, 255);
    }
.mz-interactive-info-block--white .mz-interactive-info-block__button {
    background-color: #0D84EB
  }
.mz-interactive-info-block--white .mz-interactive-info-block__button:focus,
    .mz-interactive-info-block--white .mz-interactive-info-block__button:hover {
      background-color: rgb(11, 119, 213);
    }
.mz-interactive-info-block--white .mz-interactive-info-block__button:active {
      background-color: rgb(10, 105, 189);
    }
.mz-interactive-info-block--white .mz-interactive-info-block__button:disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgb(105, 181, 247);
    }
.mz-interactive-info-block--white .mz-interactive-info-block__icon {
    background-color: rgba(255, 255, 255, 0.7);
    color: #0D84EB;
  }
.mz-interactive-info-block--white .mz-interactive-info-block__icon.mz-svg-icon,
    .mz-interactive-info-block--white .mz-interactive-info-block__icon .mz-svg-icon {
      fill: #0D84EB;
    }
.mz-interactive-info-block--white .mz-interactive-info-block__title {
    color: #444;
  }
.mz-interactive-info-block--white .mz-interactive-info-block__text {
    color: #000000;
  }
.mz-interactive-info-block--white .mz-interactive-info-block__text-clear {
    color: #888888;
  }
.mz-interactive-info-block--yellow {
  background-color: #f7ecdf;
  border: 1px solid #f2c483;
}
.mz-interactive-info-block--yellow .mz-interactive-info-block__close {
    color: #edaf4a
  }
.mz-interactive-info-block--yellow .mz-interactive-info-block__close:focus,
    .mz-interactive-info-block--yellow .mz-interactive-info-block__close:hover {
      color: rgb(235, 166, 55);
    }
.mz-interactive-info-block--yellow .mz-interactive-info-block__close:active {
      color: rgb(234, 162, 46);
    }
.mz-interactive-info-block--yellow .mz-interactive-info-block__close:disabled {
      color: rgb(246, 216, 167);
    }
.mz-interactive-info-block--yellow .mz-interactive-info-block__button {
    background-color: #edaf4a
  }
.mz-interactive-info-block--yellow .mz-interactive-info-block__button:focus,
    .mz-interactive-info-block--yellow .mz-interactive-info-block__button:hover {
      background-color: rgb(235, 166, 55);
    }
.mz-interactive-info-block--yellow .mz-interactive-info-block__button:active {
      background-color: rgb(234, 162, 46);
    }
.mz-interactive-info-block--yellow .mz-interactive-info-block__button:disabled {
      color: rgba(255, 255, 255, 0.5);
      background-color: rgb(246, 216, 167);
    }
.mz-interactive-info-block--yellow .mz-interactive-info-block__icon {
    background-color: #ffffff;
    color: #f2c483;
  }
.mz-interactive-info-block--yellow .mz-interactive-info-block__icon.mz-svg-icon,
    .mz-interactive-info-block--yellow .mz-interactive-info-block__icon .mz-svg-icon {
      fill: #f2c483;
    }
.mz-interactive-info-block--yellow .mz-interactive-info-block__title {
    color: #edaf4a;
  }
.mz-interactive-info-block--yellow .mz-interactive-info-block__text {
    color: #000000;
  }
.mz-interactive-info-block--active {
  border: 1px solid rgb(202, 229, 252);
}
/* Price breakdown */
.mz-breakdown.mz-horizontal-breakdown > :first-child {
    border-top: 1px solid #dddddd;
  }
.mz-price-breakdown__item {
  border-bottom: 1px solid #dddddd;
}
.mz-price-breakdown__icon {
  fill: #0D84EB;
}
/* Trip tag */
.mz-trip-tag {
}
.mz-trip-tag .mz-svg-icon {
  fill: #ffffff;
}
.mz-trip-tag .mz-font-icon {
  color: #ffffff;
}
/* Options list */
.mz-options__item {
  color: black;
}
.mz-options__list::-webkit-scrollbar-thumb {
    background: #0D84EB;
  }
.mz-options--focus,
.mz-options--focus:hover,
.mz-options__item:hover {
  background-color: rgba(13, 132, 235, 0.2);
}
.mz-options--active {
  background-color: #0D84EB;
  color: #ffffff
}
.mz-options--active:hover {
    background-color: #0D84EB;
  }
.mz-options--active-clear {
  background-color: #f2f2f2
}
.mz-options--active-clear:hover {
    background-color: #f2f2f2;
  }
/* Radio */
.mz-radio__title {
  color: #0D84EB !important;
}
.mz-radio--disabled {
  color: #dddddd;
}
.mz-success-label {
  color: #8bc34a;
}
/* Form: Commons */
.mz-label-panel__title {
  background: #0D84EB;
  color: #ffffff;
}
.mz-label-panel__container {
  background: #ffffff;
}
.mz-dropdown {
}
.mz-dropdown__contained {
  background: #ffffff;
}
.mz-dropdown__triangle {
  border-color: transparent transparent #ffffff #ffffff;
}
.mz-dropdown--primary > .mz-dropdown__contained {
    color: #ffffff;
    background: #0D84EB;
  }
.mz-dropdown--primary > .mz-dropdown__contained .mz-dropdown__triangle:after {
      border-color: transparent transparent #0D84EB
        #0D84EB;
    }
.mz-dropdown--error > .mz-dropdown__contained {
    color: #c7254e;
    background: #fff3f3;
    border: 1px solid #f75757;
    border-radius: 2px;
  }
.mz-dropdown--error > .mz-dropdown__contained .mz-dropdown__triangle:after {
      border-color: transparent transparent #fff3f3
        #fff3f3;
    }
.mz-homepage-input__icon,
.mz-compact-input__icon {
  color: #888888;
  fill: #888888;
}
.mz-compact-input__icon:hover {
  color: #0D84EB;
  fill: #0D84EB;
}
.mz-inline-label {
}
.mz-form-errors {
  color: #c45151;
}
.mz-form-errors__item {
}
.mz-tracking-status {
  color: #58b76a;
}
/* Time type selector */
.mz-time-type-selector--gray {
  background-color: #eeeeee;
}
.mz-time-type-selector--primary {
  background-color: #0D84EB;
}
.mz-time-type-selector__label--primary {
  color: rgb(9, 92, 165);
}
.mz-time-type-selector__label--primary svg {
    fill: rgb(9, 92, 165);
  }
.mz-time-type-selector__label--primary--active {
  color: #ffffff;
}
.mz-time-type-selector__label--primary--active svg {
    fill: #ffffff;
  }
.mz-time-type-selector__label--gray {
  color: #727272;
}
.mz-time-type-selector__label--gray svg {
    fill: #727272;
  }
.mz-time-type-selector__label--gray--active {
  color: #0D84EB;
}
.mz-time-type-selector__label--gray--active svg {
    fill: #0D84EB;
  }
/* End of time type selector */
.mz-promo:hover {
    background: #eeeeee;
    border: 1px solid #dddddd;
  }
.mz-promo-description {
  color: #0D84EB;
}
/* Lightbox */
.mz-lightbox__title--clear {
  color: #0D84EB !important;
}
.mz-route-details-search-date--mobile {
    background-color: rgb(245, 245, 245);
  }
.mz-route-details-search-date__label--mobile {
      color: #888888;
    }
/* Tooltip */
.mz-tooltip {
  background-color: #0D84EB;
}
.mz-tooltip--top .mz-tooltip:after {
    border-top: 8px solid #0D84EB;
  }
.mz-tooltip--bottom .mz-tooltip:after {
    border-bottom: 8px solid #0D84EB;
  }
@media all and (min-width: 1280px) {
    .mz-tooltip--left .mz-tooltip:after {
      border-left: 8px solid #0D84EB;
    }
  }
@media all and (min-width: 1280px) {
    .mz-tooltip--right .mz-tooltip:after {
      border-right: 8px solid #0D84EB;
    }
  }
/* Credit card */
.mz-cc-container {
  border: 1px solid #0D84EB;
}
/* GBT Specific styles */
.corp-gbt .mz-link {
    color: #0D84EB
  }
.corp-gbt .mz-link:hover,
    .corp-gbt .mz-link:focus {
      color: rgb(9, 92, 165);
    }
.corp-gbt .mz-sidebar__item:hover {
      background: rgb(0, 27, 71);
    }
.corp-gbt .mz-sidebar__item--important,
  .corp-gbt .mz-sidebar__item--important .mz-icon {
    background: rgb(0, 27, 71);
  }
.corp-gbt .mz-header-bar__status {
    background-color: #0D84EB;
  }
.corp-gbt .mz-filtermenu {
    background-color: #0D84EB;
  }
.corp-gbt .mz-filtermenu_button {
    background-color: #0D84EB;
  }
.corp-gbt .mz-filtermenu_button:focus,
    .corp-gbt .mz-filtermenu_button:hover,
    .corp-gbt .mz-filtermenu_button--hover {
      background-color: rgb(11, 119, 213);
      border-color: rgb(10, 111, 199) !important;
    }
.corp-gbt .mz-filtermenu_button--active,
    .corp-gbt .mz-filtermenu_button--active_state:active {
      background-color: rgb(32, 145, 243) !important;
      border-color: rgb(22, 140, 243) !important;
    }
.corp-gbt .mz-filtermenu_button--mobile {
    color: #0D84EB !important;
  }
.corp-gbt .mz-filtermenu_button--mobile .mz-icon {
      fill: #0D84EB;
    }
.corp-gbt .mz-filtermenu_button--mobile.mz-filtermenu_button--hover {
      background-color: #0D84EB !important;
    }
.corp-gbt .mz-icon-container {
    fill: #002663;
  }
.corp-gbt .mz-compact-input__label {
    color: #888888;
  }
.corp-gbt .mz-transinput--required .mz-transinput__bar:after,
    .corp-gbt .mz-transinput--required .mz-transinput__bar:before {
      background: #ed5929;
    }
.corp-gbt .mz-transinput__placeholder {
    color: #8b8d8e;
  }
.corp-gbt .mz-rounded-header {
    border: 1px solid rgba(13, 132, 235, 0.5);
    background-color: rgba(13, 132, 235, 0.2);
  }
.corp-gbt .mz-rounded-header__title {
    color: #0D84EB;
  }
.corp-gbt .mz-interactive-info-block--blue {
    background-color: rgba(13, 132, 235, 0.1);
  }
.corp-gbt .mz-success-label {
    color: #0D84EB;
  }
/* HACK: To avoid breaking the current app, we're adding this z-index as a temporary solution until we revamp the other sections */
div[data-radix-popper-content-wrapper] {
  z-index: 10000 !important;
}
div[data-ui-overlay] {
  z-index: 999999 !important;
}
div[data-ui-dialog-portal] {
  z-index: 999999 !important;
}
div[data-ui-dialog-content] {
  z-index: 999999 !important;
}
