._3ca09A {
  background-color: white;
}
._39Z7Yx {
  opacity: 0.5;
}
._25mHqo {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 16px;
}
.uiWc77 {
  border-width: 2px;
  border-color: #4287c6;
  width: 1px;
  margin-left: 6px;
  border-style: hidden hidden hidden dotted;
}
._1RVi06 {
  color: #4287c6;
  fill: #4287c6;
}
.mGWTqK {
  margin: 0 0 0 -5px;
    padding: 2px 5px;
}
._3EtH1n {
  margin-top: 10px;
}
.VSVylK ._2eXQJP {
    color: #0D84EB;
    padding: 0 10px;
  }
._3Mweg4 {
}
