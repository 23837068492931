.xFrpEJ {
  color: #212121;
}

._1rx2Ef {
  font-size: 48px;
  font-weight: 400;
  line-height: 50px;
}

.R0w-7s {
  font-size: 48px;
  font-weight: 300;
  line-height: 50px;
}

._1Cukog {
  margin-top: 20px;
  font-size: 24px;
  font-weight: 300;
}
