._3q7f1z {
  color: #3f9952;
  fill: #3f9952;
}

.mkboZV {
  color: #b83b0f;
  fill: #b83b0f;
}

._3CbBzI {
  margin-left: 5px;
  cursor: default;
}

/* GBT specific styles */

.corp-gbt ._3q7f1z {
    color: #009bbb;
    fill: #009bbb;
  }

.corp-gbt .mkboZV {
    color: #8d8d8e;
    fill: #8d8d8e;
  }
