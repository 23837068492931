._1LzbFt {
  width: 100%;
  min-height: 180px;
  padding: 20px 0;
  background-color: white;
  color: #444;
  border: 1px solid #e6e6e6;
}
._1GnyCC {
  background-color:  #f9f9f9;
}
._2oXIZT {
  font-size: 14px;
}
._3sdxpA {
  padding-bottom: 10px;
}
/* start-of: price column */
._1QcpSj {
  padding: 0 20px;
  font-size: 12px;
}
._3Dloq1 {
  font-size: 28px;
  line-height: 26px;
}
._3EaElV {
  font-size: 12px;
  color: #b83b0f;
  margin-right: 10px;
  font-weight: 500;
  background-image: linear-gradient(transparent 7px, #b83b0f 7px, #b83b0f 9px, transparent 9px);
}
._2wwVzc {
  font-size: 14px;
}
._3NFtZt {
  margin-top: 20px;
}
.R6ZW4i {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold
}
.R6ZW4i > span {
    white-space: nowrap;
    padding-left: 5px;
  }
._1Y9CQ_ {
  white-space: nowrap;
  color: #c3c3c3;
}
._2tnKYW {
  font-size: 18px !important;
  padding: 10px 20px;
  margin-top: 10px;
  white-space: nowrap;
}
._3fII-x {
}
._15qVL5 {

}
.bIxW97 {
  margin-top: 10px;
  margin-right: 10px;
}
@media all and (max-width: 1280px) {
  ._15qVL5 {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .bIxW97 {
    margin-right: 0;
  }
}
/* end-of: price column */
._3kbbbY {
}
._3kbbbY._1LzbFt {
  position: relative
}
._3kbbbY._1LzbFt:before {
    top: 0;
    left: -1px;
    position: absolute;
    height: 100%;
    content: '';
  }
/* GBT specific styles */
.corp-gbt ._1GnyCC {
    background-color: transparent;
  }
.corp-gbt .ADtewE {
    color: #008767;
    fill: #008767;
  }
.corp-gbt ._1Y9CQ_ {
    color: #b6b6b6;
  }
.corp-gbt ._2tnKYW {
    background: #002663;
  }
