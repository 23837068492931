._3CwXPF, ._3-FTGD, ._9swsbQ{
  border-radius: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
._3CwXPF {
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 2px solid #0D84EB;
}
._3-FTGD {
  width: 20px;
  height: 20px;
  background: #0D84EB;
}
._9swsbQ {
  background: #0D84EB;
  border: 3px solid white;
  box-shadow: 0 0 0 3px #0D84EB;
  width: 16px;
  height: 16px;
}
._2Av0pU {
  position: absolute;
  top: -64px;
}
._2MKdfS {
  background-color: #0D84EB;
  color: #FFFFFF;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;

  position: absolute;
  z-index: 1
}
._2MKdfS:after {
    content: " ";
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    top: 100%;
    left: 50%;
    border-left: solid 6px transparent;
    border-right: solid 6px transparent;
    border-top: solid 14px #0D84EB;
  }