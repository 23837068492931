._2jzbE_ {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  float: right;
  width: 20px;
  height: 24px;
  text-align: center;
  cursor: pointer;
  /* Important for IE11*/
  margin-left: 2px;
  line-height: 24px;
}
._2qIH5o {
}
._2qIH5o._3HO-pk {
  top: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  font-size: 18px !important;
}
/* Loading indicator */
._3ldo9w {
  position: absolute;
  -webkit-transform: scale(0.8, 0.8);
          transform: scale(0.8, 0.8);
  top: -11px;
  right: -15px;
}
._3f0SCT ._2jzbE_ {
    display: none;
  }
._2lBRii {
}
._2lBRii._2TEKSD ._3cFteZ {
    padding: 20px 0px;
    height: 56px;
  }
._2lBRii._2TEKSD ._2jzbE_ {
    width: 30px;
    height: 56px;
    line-height: 56px;
  }
._2lBRii._2TEKSD ._2qIH5o {
    pointer-events: none;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: text;
  }
._3cFteZ {
  background: transparent;
  outline: none;
  padding: 0;
  height: 24px;
  width: 100%;
  border: none;
}
._2lBRii._1Plifv ._3cFteZ::-webkit-input-placeholder {
  text-transform: uppercase;
}
._2lBRii._3BMg4y ._3cFteZ {
  text-overflow: ellipsis;
}
._1ADkWT {
  opacity: 1;
}
._16gQEv {
  padding-right: 2px;
  line-height: 24px;
}
/* Classic rectangle style input */
.OaJkcy {
  border: 1px solid #ddd;
  padding: 0;
}
.OaJkcy ._3cFteZ {
    height: 40px;
    padding: 0 5px;
  }
.OaJkcy ._2jzbE_ {
    height: 40px;
  }
/* Material like input */
._3CF2Wy {
  border-bottom: 1px solid rgba(182, 182, 182, 0.5);
}
._3CF2Wy ._3cFteZ {
    height: 40px;
    padding: 0;
  }
._3CF2Wy ._2jzbE_ {
    height: 40px;
  }
/* Disable border for any modifier */
.-SydYi {
  border: none;
}
