._1J41U1 {
  padding: 100px;
  background-color: #0D84EB;
}
.naIeir {
  min-width: 320px;
  max-width: 80%;
  padding: 40px 30px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
}
._6EMB0o {
  height: auto;
  margin-right: 15px;
}
._6EMB0o img {
    height: 48px !important;
    width: 120px;
  }
._3KEq7i {
  font-size: 24px;
  color: #000000;
  padding-top: 10px;
}
._3KETby {
  padding: 10px
}
._3KETby:last-child {
    margin-bottom: 0;
    font-size: 18px;
  }
._1AmKMa {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._1AmKMa .naIeir {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
.corp-renault ._1J41U1 {
    background-color: #FFFFFF;
  }
