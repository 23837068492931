._1podzd {
  width: 100%;
}
._3MH0jo {
  border: 1px solid #ddd;
  padding: 8px 5px 8px 10px;
}
.D9RFnS {
}
._2y2rzS {
  margin-top: 4px;
}
