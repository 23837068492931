
  @media all and (max-width: 600px) {
._3X6HYN {
    text-align: center
}
  }
._2_PlgL {
  margin: 10px 0;
}
._385zMx {
  margin: 0 5px;
  font-weight: 600;
  min-width: 25px;
}
@media all and (max-width: 600px) {
._3u4JsN {
    width: 50%;
    text-align: right;
    padding: 0 10px 0 0
}
  }
._3u4JsN {
  margin: 0;
  font-weight: bold;
  width: 47%;
  float: left;
}
._2KhEU_ ._3u4JsN {
    -webkit-flex-grow: 1;
            flex-grow: 1;
  }
._3KdWiY {
  margin: 8px 0 0;
  width: 47%;
  float: right;
}
