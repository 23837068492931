
  .RusCS3:first-child {
    padding-right: 15px;
  }

._2fSt36 {
  width: 100%;
}

._1j0hbO {
}
