._1Hpv0n {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
}

._3acMch {
}

._1gAcAy {
}

._24XgTM {
}

._f5C7V {
}

._3i2_8s {
  margin-left: 10px;
}

.CHXeZG {
  font-weight: 600;
  margin-left: 15px;
  margin-right: 5px;
}

._3zvoiw {
  font-size: 30px;
  cursor: pointer;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
}
