._2C5M7z,
._3PNg7i {
  width: 100%;
}
._2C5M7z {
  color: #4D4F53;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-weight: 100;
}
._2hP3R1,
._3n6H7O {
  display: inline-block;
  vertical-align: middle;
}
._2hP3R1 {
  width: 24px;
  margin-right: 20px;
}
._2z1bPv {
  color: #002663;
  font-size: 12px;
  font-weight: 500;
}
._Xn4Qj {
  color: #4D4F53;
  font-size: 14px;
  font-weight: 100;
}
._3nMf3m {
  position: relative;
  width: 24px;
  height: 24px;
  margin: auto;
  border: 1px solid #009BBB;
  border-radius: 24px;
  background-color: #FFFFFF;
}
._3nMf3m i {
    margin: 3px 4px;
    font-size: 15px;
  }
._3nMf3m svg {
    margin: 6px;
  }
._2eNUjk {
  width: 10px;
  height: 10px;
  color: #009BBB;
  fill: #009BBB;
}
._2AB6nQ ._2hP3R1 {
    margin-right: 10px;
  }
