/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

._1rQ20z {
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

/* Navigation */

.mr9gCa {
  position: relative;
  height: 50px;
  width: 100%;
  overflow: hidden;
  z-index: calc(6000 + 1);
}

.EJVWZ_ {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 20px;
  font-weight: 400;
  z-index: 0;
}

.zTDTwG {
  position: relative;
  min-width: 0;
  min-width: initial;
  z-index: 1;
  height: 100%;
}

._1Fnvlc {
  font-size: 35px;
  width: 30px;
  margin-left: -11px;
}

/* Content */

.xSJ5p- {
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  -ms-touch-action: pan-y;
}
