.w2FIIo {
  padding: 20px;
  margin-top: 40px;
  max-width: 60%;
  min-width: 380px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  border-radius: 3px;
}
._3eTxS1 {
  margin: 0 auto;
}
._3eTxS1 img {
    height: 60px;
  }
._294Czq {
  font-size: 24px;
  color: #000000;
  margin: 15px auto;
}
._3uPph4 {
  margin-bottom: 30px;
  font-size: 16px;
  color: #009bbb;
}
._3fdHrs {
  border-bottom: 1px solid #000000;
  width: 100%;
}
