._3WOVwZ {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

._8MhLeX {
  position: relative;
  width: 100%;
  max-width: 1500px;
  padding: 5% 2.5% 8% 2.5%;
}

._8MhLeX._3_eHlL {
  padding: 5% 4% 5% 4%;
}

._3seGBF {
  color: #002663;
  font-weight: bold;
  margin: 20px 0 40px 0;
}

._3seGBF._3_eHlL {
  text-align: center;
  line-height: 1;
}

.lobDGl {
  color: #8b8d8e;
  margin-bottom: 20px;
}

._3OLcx3 {
  color: #009BBB;
  margin-left: 2px;
}
