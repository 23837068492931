.lShlQz {
  max-width: 50%;
  min-width: 380px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
  text-align: center;
  grid-gap: 20px;
  gap: 20px;
}
._2JoxWX {
  min-width: 100%;
  max-width: 100%;
  padding: 20px;
  box-shadow: none;
}
.qZdQlT {
  font-size: 18px;
  color: #002664;
}
._3Z_abQ {
  font-size: 30px;
  color: #002664;
}
._2dDiFJ {
  font-size: 12px;
  color: #002664;
}
