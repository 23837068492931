._3Q4klO {
  position: absolute;
  width: 70%;
  max-width: 920px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;

  background: white;
  border-radius: 5px 5px 0 0;
  border: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  box-shadow:         1px 1px 17px 0px rgba(0, 0, 0, 0.45)
}
@media all and (max-width: 600px) {
._3Q4klO {
    width: 100%;
    border-radius: 0
}
  }
._2IL7EI {
  margin-top: -50px;
  margin-bottom: 5px
}
._2IL7EI > div:not(:first-child) {
    margin: 0 7px;
  }
._2UrE6v {
  position: absolute;
  cursor: pointer;
  right: 5px;
  top: 5px;
  padding: 5px;
}
._2UrE6v svg {
    width: 12px !important;
    height: 12px !important;
  }
._2ab241 {
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  margin-bottom: 3px;
  cursor: pointer
}
._2ab241:not(._3-TbfI) {
    background: #d7d7d7;
    cursor: auto;
  }
._3-TbfI {
}
._3Ye87v, .sY26q- {
  width: 55px !important;
  height: 55px !important;
  border: 0;
}
._2Z73Ko {
  width: 35px !important;
  height: 35px !important;
}
._3Ye87v {
  border: 1px solid #e4e4e4;
  background: white;
}
._2YVf4c,
._2GPZbJ {
  width: 100px;
  height: 100px;
  border: 1px solid #e4e4e4;
  background-color: white;
}
._2GPZbJ {
  font-size: 32px;
  border-radius: 50%;
}
._1YaMH_ {
  font-size: 14px;
}
._1YaMH_ span {
    margin-right: 5px;
  }
._8XbdQy ~ ._1YaMH_ {
  margin-top: 30px;
}
.jVXg9z {
  font-size: 12px;
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease;
  width: 100%;
}
._2Gtgio ._3Dq0az{
    background-color: #F3F3F3;
    background-position: 98% center;
  }
._2Gtgio .jVXg9z {
    transition: max-height 1s ease;
    height: auto;
    max-height: 1300px;
    overflow: auto;
  }
._14YTbL{
  position: relative;
  width: 80%;
}
._3iLOxv, ._3WZKk4 {
  font-size: 22px;
}
._3iLOxv {
  padding-top: 10px
}
._3iLOxv > span {
    margin-right: 10px;
  }
._8XbdQy {
  position: relative;
  margin: 0;
  font-size: 22px;
  width: 100%;
}
._8XbdQy ._1NhhFr {
  position: absolute;
  opacity: 0;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  -webkit-animation: SV0diW 8s linear infinite 0s;
          animation: SV0diW 8s linear infinite 0s;
  text-align: center;
}
._8XbdQy ._1NhhFr._3kLNL1 {
  -webkit-animation: _3aVZ4U 8s linear infinite 5s;
          animation: _3aVZ4U 8s linear infinite 5s;
}
._3kLNL1 {
  text-transform: capitalize;
}
._3WZKk4 {
}
.VOqV8Y {
  font-size: 14px;
}
._3chXVQ > div {
  padding: 0 2px;
}
._6fJJrm {
  -webkit-align-self: stretch;
          align-self: stretch;
  height: 42px;
  font-size: 14px;
  padding: 10px;
  margin-top: 22px;
  width: 100%;
}
._29mEua {
  margin: 5px 0;
  margin-bottom:10px;
  font-size: 14px;
}
/* Title rotation animations */
@-webkit-keyframes SV0diW {
  0% { opacity: 1; -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; height: 0px; }
  10% { opacity: 1; height: 30px; }
  57% { opacity: 1; height: 30px; }
  63% { opacity: 0; height: 30px; }
  100% { opacity: 0; }
}
@keyframes SV0diW {
  0% { opacity: 1; -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; height: 0px; }
  10% { opacity: 1; height: 30px; }
  57% { opacity: 1; height: 30px; }
  63% { opacity: 0; height: 30px; }
  100% { opacity: 0; }
}
@-webkit-keyframes _3aVZ4U {
  0% { opacity: 1; -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; height: 0px; }
  10% { opacity: 1; height: 30px; }
  33% { opacity: 1; height: 30px; }
  37% { opacity: 0; height: 30px; }
  100% { opacity: 0; }
}
@keyframes _3aVZ4U {
  0% { opacity: 1; -webkit-animation-timing-function: ease-in; animation-timing-function: ease-in; height: 0px; }
  10% { opacity: 1; height: 30px; }
  33% { opacity: 1; height: 30px; }
  37% { opacity: 0; height: 30px; }
  100% { opacity: 0; }
}
