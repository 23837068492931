._1JT2HO {
  background: #FFFFFF;
}
._3f2KWc {
  margin-bottom: 20px
}
._3f2KWc:first-child ._7BMyG4 {
      padding-top: 0 !important;
    }
@media (min-width: 601px) {
  ._3QlYEc {
    padding-right: 20px;
  }
  ._7BMyG4 {
    padding-top: 0 !important;
  }
}
