._2QEaCJ {
  color: #212121;
  border-bottom: 1px solid #eee;
}
._2hMIZj {
  margin-left: 5px;
  padding-bottom: 20px
}
._2hMIZj:first-child {
    padding-right: 20px;
  }
._2hMIZj:nth-child(2) {
    padding-left: 20px;
    border-left: 1px solid #eee;
  }
._26p2hr {
  margin-top: 30px;
  margin-bottom: 20px;
}
.yVopMu {
  font-weight: bold;
  margin-bottom: 10px;
}
.QgCyKd span > span {
    font-weight: bold;
  }
.patge1 {
  margin-top: 10px;
}
.WsZgAX {
  margin-left: 3px;
}
._3Tq83r {
  margin-top: 15px;
  padding: 5px 30px;
}
