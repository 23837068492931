.kq0lHP {
  position: absolute;
  top: 100%;
  border-radius: 4px;
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.45);
  -webkit-transform: translate3d(0, 2px, 0);
          transform: translate3d(0, 2px, 0);
  padding: 4px 4px 15px 4px;
  z-index: 100000;
  background: #fff;
  width: 100%;
  max-width: 360px;
  min-width: 320px;
}

.mWMunD {
  margin-left: -2px;
}

._31imJn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
