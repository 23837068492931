.gbW9_E > * {
    margin-right: 10px;
  }
  .gbW9_E > *:last-child {
    margin-right: 0;
  }
._3tGl_Y {
  color: #212121;
  margin: 30px 0 0 0;
  padding: 0 0 0 10px;
  font-size: 14px;
}
