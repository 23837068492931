._33iwVx {
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
          flex-flow: column nowrap;
}

._3brN8Q {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
}

._3Qhteb ._2Dsy9t {
    margin-bottom: 0;
    border-radius: 0;
    background-color: transparent;
    background-color: initial;
  }

._3Qhteb ._2Dsy9t svg {
      width: auto !important;
      width: initial !important;
      height: auto !important;
      height: initial !important;
    }

._3Qhteb {
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

._2Sj4hd {
}

._1OPEc2 {
}

._1FYFrX {
}

._3n8ZVo {
}

.HdC6uG {
}

._3mJPa7 {
}

._1F9gmM ._2Dsy9t {
    background: transparent !important;
  }

._2Dsy9t {
  height: 50px;
  width: 50px;
  min-width: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

._2i_kCb {
  border: 1px solid transparent;
  border-radius: 50%;
}

._2dd_aA {
  position: absolute;
  padding: 5px;
  z-index: 2;
  top: 0px;
  right: 0px;
}

._3mEUl4 {
  font-weight: bold;
  font-size: 14px;
}

.L_xOsw {
  font-size: 14px;
}

._3mEUl4 + .L_xOsw {
  margin-top: 5px;
}

._3mEUl4 + ._1KRKyf,
.L_xOsw + ._1KRKyf {
  margin-top: 20px;
}

._3Kjl_p {
  margin-left: 40px;
}

._3Kjl_p ._2B_d3u {
    width: 100%;
    margin-right: 0;
  }

._3Kjl_p ._2B_d3u + ._2B_d3u {
    margin-top: 10px;
  }

._2B_d3u {
  margin-right: 10px;
  white-space: nowrap;
}

._2VixIR {
  border: none;
  background: none;
}
