._2REfTJ {
  position: relative;
}
@media all and (max-width: 959px) {
  ._2Z3bQu {
    display: block;
    padding: 0 20px;
  }

  ._2REfTJ {
    width: 100%;
  }

  ._2jnnvG {
    margin-top: 40px;
    padding-top: 20px;
  }

  .BBPYQH {
    margin-bottom: 40px;
  }
}
@media all and (min-width: 960px) {
  ._2REfTJ {
    width: 670px;
    max-width: 670px;
    padding-right: 40px;
  }

  ._2Z3bQu {
    /* Important for IE */
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
  }

  ._2jnnvG {
    width: 250px;
    max-width: 250px;
    padding-top: 48px;
  }

  .BBPYQH {
    display: none;
  }

  .ItiDEw {
    width: 670px;
    max-width: 670px;
    padding-right: 40px;
    margin-right: 250px;
  }
}
