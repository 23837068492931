._3Llf_h {
  padding: 30px 50px 20px;
}
.CdNtZ9 {
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 30px;
  text-align: center;
}
.ggbTGp {
  padding: 0px 10px
}
@media all and (max-width: 960px) {
.ggbTGp {
    margin-bottom: 20px
}
    .ggbTGp:last-child {
      margin-bottom: 0;
    }
  }
.OaPC_V {
  list-style: none;
  padding: 0;
  margin: 0;
}
._3tqVEu {
  position: relative;
  border-radius: 3px;
  padding: 20px
}
._3tqVEu:after {
    position: absolute;
    bottom: -10px;
    left: 15px;
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0;
  }
._2ml4Iv {
  vertical-align: middle;
  margin-right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  background-size: 50px 50px;
  background-repeat: no-repeat;
}
._3LJ5bW {
  margin-top: 10px;
}
._1GyEve {
  padding: 5px 0 0 0px;
  margin: 0;
  font-size: 18px;
  vertical-align: middle;
}
._2WNODf {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  border: 0;
}
