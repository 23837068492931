._2HA0lr {
  padding: 20px 20px 15px 20px;
}
._3RH4cx {
  font-size: 20px;
  color: #0D84EB;
}
._2bpr21 {
  font-size: 14px;
  color: #b6b6b6;
}
._3XD-rT {
  cursor: pointer;
}
