/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

/* Lightbox renderer */

._3behtM {
  min-height: 100vh;
  width: 100%;
  position: relative;
}

._37HbfJ {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
}

._3W_L9u {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  transition: all 0.4s cubic-bezier(.1, .7, .4, 1);
}

._168WB7 {
  transition: all 0.4s cubic-bezier(.1, .7, .4, 1);
}

/* Lightbox animation */

._3fb_01 ._3W_L9u, ._3y7DnE._1326Kw ._3W_L9u {
    opacity: 0;
  }

._3fb_01 ._168WB7, ._3y7DnE._1326Kw ._168WB7 {
    opacity: 0;
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

._3fb_01._2ohT3Z ._3W_L9u, ._3y7DnE ._3W_L9u {
    opacity: 1;
  }

._3fb_01._2ohT3Z ._168WB7, ._3y7DnE ._168WB7 {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

/* Mobile screen renderer */

._3ml0Ns {
}
