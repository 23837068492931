._37UOFI {
}

._37UOFI:first-child {
    padding-left: 0;
  }

._37UOFI._1HrqDA {
  line-height: 14px;
  font-size: 14px;
  padding-bottom: 0;
  padding-left: 0;
}

._2m7CLz {
  width: auto;
  height: auto;
}

._3PF021 {
  min-width: 120px;
  text-align: center;
  font-size: 12px;
  padding: 4px;
}

._1y-4Xd svg {
    min-width: 14px !important;
    min-height: 14px !important;
    width: 14px !important;
    height: 14px !important;
  }
