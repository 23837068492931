._3Qumer {
  background: #FFFFFF;
}
._17oLrl {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin: 40px 0 20px 0;
}
._1-I2zF ._17oLrl {
    -webkit-flex-flow: column nowrap;
            flex-flow: column nowrap;
    margin-bottom: 5px;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }
._1sO1J6 {
  margin-top: 20px;
}
.D_-KHs, .kc-QTj {
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 601px) {
  .kc-QTj > div {
    padding-right: 20px;
  }

  .D_-KHs, .kc-QTj {
    margin-bottom: 0;
  }
}
._1IoXXc {
  opacity: 1;
  overflow: visible !important;
}
._1IoXXc .bjgY13 {
    transition: padding-top 0.3s ease;
    padding-top: 0;
  }
._2f-2X7 {
  opacity: 0;
}
._2f-2X7 .bjgY13 {
    padding-top: 20px;
  }
._2QzWta {
  margin-top: 20px;
  margin-left: -10px;
}
._2QzWta span {
    color: #727272;
  }
._2QzWta :first-child {
    padding-bottom: 0;
  }
.S6HiR- {
  margin-top: 30px;
  margin-right: 10px;
}
