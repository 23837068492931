/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

._1s7DGT {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  z-index: 7000;
  transition: opacity 0.2s ease;
}

._3_Sui6 {
  pointer-events: none;
  opacity: 0;
  width: 0;
  height: 0;
}

._23_c5Z {
  opacity: 0;
}
