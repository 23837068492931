/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */
/* Elements that cover the entire screen */
/* Dialogs and modals. */
/* Elements usually triggered by hovering or activating inputs by the user. */
/* Elements that support the overall layout, such as a fixed header or footer. */
/* Elements that stand out for a particular reason. */
/* Reserved for the background cover. */
._2j2VgN {
  width: 100%;
  margin: 40px 0
}
._2j2VgN:first-child {
    margin: 0;
  }
._2j2VgN:last-child {
    margin: 0;
  }
._35Hg52 {
  position: relative;
  width: 100%;
  max-width: 660px;
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  color: #4D4F53;
}
._8s_Fum {
  position: absolute;
  top: 40px;
  left: 30px;
  font-size: 14px;
}
._15zP9l {
  font-size: 10px;
  font-weight: bold;
}
.er1Qtm {
  text-align: center;
  font-size: 40px;
  margin-bottom: 40px;
}
._3Dxvhm {
  width: 100%;
  display: table;
  table-layout: fixed;
}
._24tgEz {
  display: table-row;
  line-height: 30px;
}
._1kM6bA,
._3dgoMT {
  font-size: 16px;
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
._1kM6bA {
  font-weight: bold;
  color: #002663;
}
._3dgoMT {
  text-align: right;
}
._3GckEt {
  color: #ED5C2C;
  font-size: 18px;
  text-align: center;
  padding: 40px 0 20px;
}
._3iEtl3 {
  margin-top: 30px;
}
._3iEtl3 button {
    padding: 10px
  }
._3iEtl3 button:first-child {
      margin-right: 30px;
    }
._3iEtl3 button:last-child {
      border: 1px solid transparent;
    }
