.EcRnVQ {
  color: #444;
  max-width: 920px;
  width: 100%;
  margin: 0 auto 0 auto;
}
@media all and (max-width: 959px) {
  .EcRnVQ {
    width: 100%;
    padding: 0 5px;
  }
}
@media all and (min-width: 960px) {
  .EcRnVQ {
    width: 920px;
  }
}
