._1ygsbF {
}

._3cZr1Y ._1-1gi2 {
    color: #009245;
  }

._3cKG3m ._1-1gi2 {
    color: #ff9c00;
  }

._1p7rY5 {
  margin-top: 3px;
  -webkit-align-items: start;
          align-items: start;
}

._1-1gi2 {
  font-size: 12px;
  font-weight: bold;
}

.NyE5FR {
  color: #b2b2b2;
  margin-left: 6px;
  text-transform: lowercase;
}

._37g5Y5 {
  font-size: 14px;
  color: #444444;
  margin-left: 6px;
  text-transform: uppercase;
}

._1zJ7ty {
  width: 100%;
}
