._3mYY1D {
  margin-top: 40px;
  margin-bottom: 20px;
}

._2kZphP > * {
    margin-bottom: 10px;
  }

._2kZphP :last-child {
    margin-bottom: 0;
  }
