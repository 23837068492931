._2IC_nz,
._1griqt {
  margin-top: 20px;
}
.u_Rj-L {
  padding-left: 5px;
  color: #444;
  font-weight: 600;
}
@media (min-width: 601px) {
  ._1uErdP {
    padding-right: 20px;
  }
}
._1b2XuI {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  display: -webkit-flex;
  display: flex;
}
._2zmPwQ {
  border: solid 1px #efefef;
  border-radius: 3px;
  background-color: #f9f9f9;
  padding: 10px 16px;
}
._14VX0F {
  text-decoration: underline;
  cursor: pointer;
}
/* GBT specific styles */
.corp-gbt .u_Rj-L {
    color: #4d4f53;
  }
