.HPKcmu {
  position: relative;
}

._3Ch10S {
  position: absolute;
  top: -52px;
  left: -20px;
  width: 39px !important;
  height: 55px !important;
}
