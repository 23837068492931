._1ddYXJ {
  width: 100%;
  min-width: 100px;
}

._2vGxVV {
  font-size: 14px;
  font-family: inherit;
}

._3VnHhZ + ._3VnHhZ {
  margin-left: 2px;
}

._1UriZG:first-child {
    margin-right: 20px;
  }

._3VnHhZ .zFu7dR {
    font-size: 16px;
  }

._3VnHhZ ._1Ad0eA {
    font-size: 24px;
  }

._14Ohm4 {
  max-width: 45px;
  text-align: left;
}

._14Ohm4._1Vbmqy {
  pointer-events: none;
}
