.m83O0M {
  width: 250px;
  height: auto;
  color: #b2b2b2;
  font-size: 16px;
  border: 1px solid #e6e6e6
}
@media (min-width: 960px) {
.m83O0M {
    border-left: 0;
    border-right: 0
}
  }
.m83O0M._3DclXX {
    width: 100%;
    border-left: 0;
    border-right: 0;
  }
.m83O0M._3DclXX .bLZ72p {
      padding: 4px 30px;
    }
.m83O0M._3DclXX._1yoRy5 .bLZ72p {
      padding: 4px 0;
    }
.bLZ72p {
  padding: 10px 0
}
.bLZ72p:not(:last-child) {
    border-right: 1px solid #e6e6e6;
  }
._19bsqA {
  width: 25px;
  height: 25px;
}
.kRvzqH {
  padding-left: 10px;
  line-height: 25px;
  min-width: 30px;
  text-align: center;
}
._1yoRy5 .kRvzqH {
    padding-left: 8px;
  }
._3M96kM {
  font-size: 11px;
  margin-top: 6px;
  text-align: center;
}
