._12OK3d {
  position: relative;
  transition: all 1s ease-in-out;
}

.ZLfk_Q {
  position: absolute;
  width: 42px !important;
  height: 28px !important;
  top: -14px;
  left: -21px;
}
