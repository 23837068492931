._2NoHXd {
  -webkit-animation: _2mARH7 2s linear infinite;
          animation: _2mARH7 2s linear infinite;
  transition: all .2s ease;
  background: transparent;
  margin: 20px;
  border-radius: 50%;
}

._2NoHXd._3wj6cB {
  margin: 0px;
}

.vpA7yf {
  stroke-dasharray: 1, 500;
  stroke-dashoffset: 0;
  stroke-linecap: round;
}

.-Qfm2W {
}

.ZxIN02 {
  margin: 0 auto;
}

._2NoHXd._1YwsCN {
  margin: 0;
}

._2NoHXd._28O3cb {
  background: transparent;
}

/* Sizes */

@-webkit-keyframes ZCpEns {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 40, 200;
      stroke-dashoffset: -15;
    }
    100% {
      stroke-dasharray: 40, 200;
      stroke-dashoffset: -55;
    }
  }

@keyframes ZCpEns {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 40, 200;
      stroke-dashoffset: -15;
    }
    100% {
      stroke-dasharray: 40, 200;
      stroke-dashoffset: -55;
    }
  }

._1OJQuR {
    width: 20px;
    height: 20px;
  }

._2DDCU5 {
    -webkit-animation: ZCpEns 1.5s ease-in-out infinite, NNWNpN 6s ease-in-out infinite;
            animation: ZCpEns 1.5s ease-in-out infinite, NNWNpN 6s ease-in-out infinite;
  }

@-webkit-keyframes A9QUmx {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 60, 200;
      stroke-dashoffset: -24;
    }
    100% {
      stroke-dasharray: 60, 200;
      stroke-dashoffset: -81;
    }
  }

@keyframes A9QUmx {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 60, 200;
      stroke-dashoffset: -24;
    }
    100% {
      stroke-dasharray: 60, 200;
      stroke-dashoffset: -81;
    }
  }

._2zoEjM {
    width: 30px;
    height: 30px;
  }

._1n3v1L {
    -webkit-animation: A9QUmx 1.5s ease-in-out infinite, NNWNpN 6s ease-in-out infinite;
            animation: A9QUmx 1.5s ease-in-out infinite, NNWNpN 6s ease-in-out infinite;
  }

@-webkit-keyframes _3-5i7x {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

@keyframes _3-5i7x {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

._2C3Elj {
    width: 50px;
    height: 50px;
  }

.JejQCu {
    -webkit-animation: _3-5i7x 1.5s ease-in-out infinite, NNWNpN 6s ease-in-out infinite;
            animation: _3-5i7x 1.5s ease-in-out infinite, NNWNpN 6s ease-in-out infinite;
  }

/* Animations */

@-webkit-keyframes _2mARH7 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes _2mARH7 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
