._3XzZIz {
  line-height: 42px;
  border-radius: 22px;
  padding-right: 20px;
  display: -webkit-inline-flex;
  display: inline-flex;
}

._2fp0HL {
  padding-left: 20px;
}

._2w1Ssx {
  height: 42px;
  width: 42px;
  min-width: 42px;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: -1px;
  font-size: 22px;
}

.edGTD0 {
  line-height: 24px;
}

._2pTdjX {
  font-size: 1.6em;
  white-space: nowrap;
}
