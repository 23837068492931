._1WCBeq > * {
    margin-right: 10px;
  }
  ._1WCBeq > *:last-child {
    margin-right: 0;
  }

._1OVfAt {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  margin-top: 10px;
}

._3KG1n- {
  margin-top: 10px;
}

._1yrMmI {
  border: solid 1px #efefef;
  border-radius: 3px;
  background-color: #f9f9f9;
  padding: 10px 16px;
}

._1X8_0L {
  text-decoration: underline;
  cursor: pointer;
}
