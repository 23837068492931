._1W5Ak6 {
  margin-top: 10px;
}
._19ZWPk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
._3tzH-O {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-basis: 50%;
          flex-basis: 50%;
}
._2Dq_S1 {
  width: 10px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}
._1AaTBw {
  margin-top: 30px;
}
._1hfbpi {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
}
._1AFLU8 {
  width: 100%;
}
@media all and (min-width: 961px) {
  ._1AFLU8 {
    width: 350px;
  }
}
