._2dCGBD {
  background: #EEEEEE;
  width: 300px;
}
._2LilUm {
  margin-top: 10px;
  padding: 10px 25px;
}
._3BJ3yq {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}
._3BJ3yq.OW37lh {
  max-height: 500px;
}
._1w7Rtg {
  transition: all 0.3s ease-in-out;
  width: 100%;
  padding: 20px;
  min-height: 50px;
  font-size: 15px;
  cursor: pointer;
  text-transform: uppercase;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.RzPKkS {
  display: -webkit-flex;
  display: flex;
}
._371Ei5 {
  margin: 0 5px;
  margin-right: 10px;
  font-size: 24px;
}
.QSLH3r {
  cursor: pointer;
  transition: all 0.5s ease;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}
.QSLH3r.OW37lh {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
._1esF0Y {
}
._3MpuTT {
}
._2jApj7 ._371Ei5 {
    color: #888888;
  }
._2Pbyp0 {
  padding-left: 40px;
}
@media all and (max-width: 960px) {
  ._2dCGBD {
    width: auto;
    text-align: center;
  }
  ._1w7Rtg {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 15px 10px;
    font-size: 12px;
  }
  ._3ThAgl {
    width: -webkit-min-content;
    width: min-content;
  }
  ._371Ei5 {
    margin: 0
  }
}
@media all and (max-width: 600px) {
  ._3ThAgl {
    display: none;
  }
}
/* GBT specific styles */
@media all and (max-width: 600px) {
    .corp-gbt ._1w7Rtg {
      padding: 13px 10px 10px;
    }
      .corp-gbt ._1w7Rtg i {
        margin: 0 8px;
        margin-right: 0;
      }
  }
