._2CnyNJ {
  padding: 100px;
  background-color: #0D84EB;
}
._2I3tus {
  max-width: 40%;
  min-width: 380px;
  padding: 40px;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px -2px #000000;
}
.hPkp1m {
  margin: 0 auto;
  width: 100%;
}
.hPkp1m img {
    height: 48px !important;
    width: 120px;
  }
._2mZ28p {
  font-size: 24px;
  color: #000000;
  margin: 15px auto;
}
._309Oiv {
  margin-bottom: 20px;
}
._2Dev7M {
  width: 100%;
  margin: 20px 0 25px;
  border-radius: 3px;
}
._3vQkjZ,
._2q-TIz,
._20sQKP {
  text-align: center;
}
._20sQKP {
  margin-top: 45px;
}
._3uqrDR {
}
._27svwh {
  display: inline-block;
}
._1DuUtm {
  padding: 0;
  border-bottom: 1px solid #ddd;
}
._1DuUtm ._2I3tus {
    min-width: 100%;
    max-width: 100%;
    padding: 30px 20px;
    box-shadow: none;
  }
.corp-renault ._2CnyNJ {
    background-color: #FFFFFF;
  }

