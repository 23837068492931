._3CVcRc,
._3EKJIg {
  display: inline-block;
}

._3CVcRc {
  color: #444;
  margin-right: 5px;
}

._3EKJIg {
  color: #b2b2b2;
}

._3boOl3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
}

._3l9oZ9 {
  margin-bottom: -10px;
}

._3l9oZ9 ._3CVcRc,
  ._3l9oZ9 ._3EKJIg {
     display: block;
  }

._2cxnq7 {
  padding-left: 20px;
  padding-right: 15px;
  margin-bottom: 20px;
}

._1LEwQ3 {
  padding-top: 15px;
  padding-bottom: 15px;
}
