/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */

/* Elements that cover the entire screen */

/* Dialogs and modals. */

/* Elements usually triggered by hovering or activating inputs by the user. */

/* Elements that support the overall layout, such as a fixed header or footer. */

/* Elements that stand out for a particular reason. */

/* Reserved for the background cover. */

._2nJyHV h1 {
    font-size: 30px;
    color: #000;
    font-weight: 300;
    margin: 30px 0;
    text-align: center;
  }

@media all and (max-width: 767px) {

._2nJyHV {
    display: none
}
  }

._3s6Tom {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  background: url(https://app-prod.mozio.com/20250410200948-c991d1c/2e6286d871905bea46685d3955d01da9.jpg) 0 0;
}

._37hJYR {
  background-position: 0 0;
}

._1tKQOw {
  background-position: 0 -300px;
}

.F33IqM {
  background-position: 0 -600px;
}

._1mhnuE {
  color: #fff;
  padding: 20px;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  background: rgba(0,0,0,.6);
}

._1mhnuE ._3cVvXc {
    display: block;
    font-size: 24px;
  }

._1mhnuE ._3cVvXc p {
      margin: 0 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

._3CpyMc {
  position: relative;
  font-size: 14px;
}

._3CpyMc p {
    display: inline-table;
    padding-right: 10px;
    margin: 0 0 10px;
  }

._3CpyMc p em span {
        font-size: 14px;
      }

._3CpyMc p span {
      display: block;
      font-size: 18px;
    }

.ANAj_8 {
  display: inline-table;
  float: right;
}

._300jrq {
  position: relative;
  height: 28px;
  width: 15px;
  display: inline-block
}

._300jrq:after {
    content: 'swap_horiz';
    font-size: 14px;
  }

._3pLQ6A {
  position: relative;
}

._7SP_qZ {
  position: relative;
  background: #eee;
  height: 300px;
}
