/**
 * Handle z-index layering appropiately.
 * @see https://github.com/mozioinc/mozio-web/issues/61
 * @see https://css-tricks.com/handling-z-index/
 *
 * The variables are declared to use by importing when we need further calculations
 * to build more granular layering, like calc($modal + 600 + 50)
 *
 * Classes that use these variables are declared in 'partials/layers'
 * to use with composition when no further granularity is required.
 */
/* Elements that cover the entire screen */
/* Dialogs and modals. */
/* Elements usually triggered by hovering or activating inputs by the user. */
/* Elements that support the overall layout, such as a fixed header or footer. */
/* Elements that stand out for a particular reason. */
/* Reserved for the background cover. */
._18ZbWe {
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
/* Input */
.ptDOUP {
  position: relative;
  padding: 0;
  width: 100%;
  height: auto;
  margin: 0;
}
._1ZyoT_ {
  padding: 0 5px;
}
/* List item */
._1oT-rI {
}
._1WEGnT {
  margin: 0 10px 0 0;
}
._2oFV39 {
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  font-size: 14px;
}
._19PGcM {
  font-size: 12px;
  font-weight: bold;
  color: #b2b2b2;
}
/* List */
._2O9wxk {
  font-size: 12px;
  padding: 0 10px;
}
._6nkt1N {
  padding: 5px 10px;
}
._3v9uAj {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 10px;
}
._1FUXR7 ._2O9wxk {
    display: none;
  }
._1FUXR7 .MTk_TB {
    display: none;
  }
/* Empty suggestions message */
._3zU2Sv {
  text-align: center;
  padding: 0 10px;
}
/* Loading */
._2YcNRv {
  margin: 20px auto;
}
/* component modifiers */
._2T-xTF._18ZbWe {
  width: auto;
  width: initial;
  position: relative;
  background-color: transparent;
  -webkit-user-select: initial;
      -ms-user-select: initial;
          user-select: initial;
}
._2T-xTF .ptDOUP input {
      width: 100%;
    }
._2T-xTF .MTk_TB::-webkit-scrollbar {
      width: 8px;
    }
._2T-xTF .MTk_TB::-webkit-scrollbar-thumb {
      background: #418ac9;
    }
._2T-xTF ._35isM0 {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 8000;
    margin-top: 42px;
    background: #FFFFFF;
    border: 1px solid #eee;
    /* Important for IE11*/
    top: 0;
  }
._2T-xTF ._1Vhl1p {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 3px 3px 0 #b6b6b6;
    z-index: 7100;
  }
._2T-xTF ._1Vhl1p:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: 25%;
    top: -6px;
    box-sizing: border-box;
    border: 8px solid #FFFFFF;
    border-color: transparent transparent #FFFFFF #FFFFFF;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: translateY(9px) rotate(135deg);
            transform: translateY(9px) rotate(135deg);
    box-shadow: -3px 3px 3px -3px #b6b6b6;
  }
._1nACd7 {
  background: white;
  border: 1px solid #eee;
}
