._1geiaK {
  width: 60%;
  margin-right: 20px;
  margin-left: auto;
  padding-bottom: 45px;
}

  .pgZLbT {
    margin-right: 0;
  }
.Rz-0oe {
  margin-bottom: 12px;
}
._2pvhTX * {
    font-size: 18px;
  }
._3mpF5E {
  color: black;
  padding-top: 17px
}
._3mpF5E * {
    font-size: 14px;
  }
.ra2OFt {
  color: black
}
.ra2OFt * {
    font-size: 14px;
  }
._3bpx7Z {
  margin-right: 5px
}
._3bpx7Z:after {
    content: ':';
  }
/* Table */
._4lar2k {
  margin: 10px 0 20px;
}
._4lar2k td {
    padding: 15px;
  }
._4lar2k th {
    padding: 15px;
    text-align: left;
  }
._1kun3Q:hover {
    background-color: #e3eef7;
  }
._1kun3Q td:not(:first-child) {
      text-align: right;
    }
/* End of Table */
._3cAPUw {
  margin: 20px 0;
  color: #727272;
}
._2ePjsY {
  padding: 10px 20px;
  margin-right: 20px;
}
