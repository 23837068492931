/* Field */
.PQ9JlG {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}
._1e_NKM {
}
._1AnzhM {
}
.OuGLWV {
  width: 100%;
}
.OuGLWV ._13gPfq {
    font-size: 24px !important;
    height: 24px !important;
    margin-right: 17px;
  }
/* Checkbox icon */
._13gPfq {
  height: 20px !important;
  font-size: 20px !important;
}
