._1t6V-C {
  -webkit-justify-content: center;
          justify-content: center;
}

._3jBtti {
  position: absolute;
  top: 60%;
  max-width: 920px;
  width: 70%;
}

._2dNIaW {
  position: absolute;
  width: 72px !important;
  height: 72px !important;
  right: 0;
  cursor: pointer;
}

.CYRjKX {
  background: #e4e4e4;
  color: 444;
}
